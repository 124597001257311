import DefaultImage from "../../assets/no-image-icon-6.png";

export function accountingFormat(num) {
  if (num === null || num === undefined) {
    return "";
  }
  if (num < 0) {
    return (
      "(" +
      Math.abs(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      ")"
    );
  } else {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

export default function getDefaultImageAsBase64(type = "png") {
  return fetch(DefaultImage)
    .then((response) => response.arrayBuffer())
    .then((buffer) => {
      const binary = [].slice.call(new Uint8Array(buffer));
      const base64String = btoa(
        binary.map((charCode) => String.fromCharCode(charCode)).join("")
      );
      const prefix =
        type === "png" ? "data:image/png;base64," : "data:image/jpeg;base64,";
      const base64 = `${prefix}${base64String}`;
      return { DefaultImage, base64 };
    })
    .catch((error) => {
      console.error("Error loading default image:", error);
      return { DefaultImage, base64: "" };
    });
}

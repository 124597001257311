import React, { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AdminRoute from "./AdminRoute";
import { AppContext } from "./AppContext";
import { encryptSession } from "./api";

function App() {
  const UserID = useMemo(() => encryptSession.getItem("us"), []);

  const [context, setContext] = useState(() => ({
    VendorName: encryptSession.getItem("cn"),   
    Slogan: encryptSession.getItem("sl"),
    VendorImage: encryptSession.getItem("cim"),
    refresh: 0,
    fetching: false,
    reporttitle: "crato",
    fetchError: false,
  }));

  const [VendorImage, setVendorImage] = useState(encryptSession.getItem("cim"));
  const [locd, setLocd] = useState(encryptSession.getItem("locd"));

  useEffect(() => {
    // Get current URL
    const currentPath = window.location.pathname;
    const isExcludedPath = [
      "/privacypolicy",
      "/signup",
      "/login",
      "/",
    ].includes(currentPath);

    if (!UserID && !isExcludedPath) {
      sessionStorage.clear();
      window.location.href = "/";
    }
  }, [UserID]);

  const memoizedContextValue = useMemo(() => {
    return {
      context,
      setContext,
      VendorImage,
      setVendorImage,
      locd,
      setLocd,
    };
  }, [context, VendorImage, locd]);

  return (
    <AppContext.Provider value={memoizedContextValue}>
      <AdminRoute />
    </AppContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

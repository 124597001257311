import { useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { encryptSession } from ".";

const useAsyncSubmit = (successFns = [], errorFns = []) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState(null);
  const { context, setContext } = useContext(AppContext);
  const token = encryptSession.getItem("tk");

  const handleAsyncSubmit = async (apiURL, requestBody, bypass) => {
    setSubmitted(true);

    if (!context.error || bypass) {
      setLoading(true);

      try {
        const response = await fetch(apiURL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "post",
          body: JSON.stringify(requestBody),
        });

        const result = await response.json();

        if (result.Error === false) {
          toast.success(`${result.Message} 👌`);
          setContext((prevContext) => ({
            ...prevContext,
            refresh: prevContext.refresh + 1,
          }));
          setSuccess(true);
          setResult(result); // Set the result state with the received data
          successFns.forEach((fn) => fn());
        } else {
          toast.error(`${result.Message} 😲`);
          errorFns.forEach((fn) => fn());
        }
      } catch (error) {
        toast.error("An error occurred while submitting the form 😲");
        console.error(error);
        errorFns.forEach((fn) => fn());
      } finally {
        setLoading(false);
      }
    }
  };

  setTimeout(() => {
    setSuccess(false);
  }, 8000);

  return {
    loading,
    submitted,
    success,
    result,
    setSubmitted,
    handleAsyncSubmit,
  };
};

export default useAsyncSubmit;
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import Tab from "../common/Tab/tab";
import { NavBar } from "../../api/data";
import { encryptSession } from "../../api";
import { IconClock } from "@tabler/icons";
import { Hidden, Tooltip } from "@mui/material";
import { Menu } from "@material-ui/icons";
import PaymentModal from "./PaymentModal";
import { AppContext } from "../../AppContext";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = {
  border: "#44dc94",
  padding: "12px 30px",
  borderRadius: "50px",
  backgroundColor: "white",
  color: "#44dc94",
  cursor: "pointer",
  fontSize: "15px",
  outline: "none",
  marginRight: "20px",
  // marginTop: "10px",
};

const logo = {
  // width: "70px",
  height: "70px",
  fontWeight: "bold",
  fontSize: "40px",
  color: "white",
  cursor: "pointer",
  marginTop: "10px",
};

function Header(props) {
  // const { onDrawerToggle } = props;
  const history = useHistory();
  const location = useLocation();
  const [pageHeder, setPageHeader] = React.useState([]);
  const { context, setContext } = React.useContext(AppContext);

  const timeoutIdRef = React.useRef(null);

  const handleActivity = () => {
    clearTimeout(timeoutIdRef.current);

    const newTimeoutId = setTimeout(() => {
      logout();
    }, 900000);

    timeoutIdRef.current = newTimeoutId;
  };

  React.useEffect(() => {
    handleActivity();
    const mouseMoveHandler = () => handleActivity();
    const keyDownHandler = () => handleActivity();

    window.addEventListener("mousemove", mouseMoveHandler);
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      clearTimeout(timeoutIdRef.current);
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleActivity]);

  var domain1 = window.location.href.split("/");
  React.useEffect(() => {
    setPageHeader(
      NavBar.filter(function (el) {
        return (
          el.id.toLocaleLowerCase() ===
          domain1[domain1.length - 2].toLocaleLowerCase()
        );
      })
    );
  }, [location]);

  
  React.useEffect(() => {
    const handleReload = () => {
      console.log("Reloading window...");
      window.location.reload();
    };

    // Subscribe to context.VendorID changes
    if (context?.VendorID) {
      console.log("Adding event listener...");
      window.addEventListener("reload", handleReload);
    }

    // Clean up event listener
    return () => {
      console.log("Removing event listener...");
      window.removeEventListener("reload", handleReload);
    };
  }, [context?.VendorID]);

  function handleTabs(tab) {
    history.push(`/${tab.url}`);
  }

  const logout = () => {
    sessionStorage.clear();
    // localStorage.clear();
    history.push("/");
    window.location.reload();
  };

  const user = encryptSession.getItem("usn");
  const customer = encryptSession.getItem("cn");

  // console.log('p', props)

  function isDueDateLessThan10Days() {
    const dueDate = new Date(context?.DueDate);
    const currentDate = new Date();
    const timeDifference = dueDate - currentDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (!context?.DueDate) {
      return false;
    }

    return daysDifference < 10;
  }

  return (
    <React.Fragment>
      {user && (
        <AppBar
          style={{ backgroundColor: "#2c3d4e", marginTop: "0px" }}
          position="sticky"
          elevation={0}
        >
          <Toolbar>
            {!props.isSidebarOpen ? (
              <Hidden mdDown>
                <IconButton
                  onClick={props.toggleSidebar}
                  edge="start"
                  sx={{
                    // border: "2px solid white",
                    color: "white",
                    marginRight: "0px",
                  }}
                  // color="red"
                  aria-label="menu"
                >
                  <Menu />
                </IconButton>
              </Hidden>
            ) : null}
            <Hidden lgUp>
              <IconButton
                // color="inherit"
                aria-label="menu"
                onClick={props.toggleMobileSidebar}
              >
                <Menu />
              </IconButton>
            </Hidden>
            <Grid container spacing={1} alignItems="center">
              <Grid xs item>
                {!user ? (
                  <div style={logo}>Esque Kenya</div>
                ) : (
                  <Typography color="inherit" variant="h5" component="h1">
                    {domain1[domain1.length - 2] === "customer"
                      ? customer?.toLocaleUpperCase()
                      : domain1[domain1.length - 2].toLocaleUpperCase()}
                  </Typography>
                )}
              </Grid>
              {/* )} */}
              {/* Invoice modal */}
              {isDueDateLessThan10Days() && (
                <Grid item>
                  <PaymentModal DueDate={context?.DueDate} />
                </Grid>
              )}

              {user ? (
                <Grid item textAlign="center">
                  {user}
                  <Tooltip title="log out">
                    <IconButton
                      color="inherit"
                      sx={{ p: 0.5 }}
                      onClick={() => logout()}
                    >
                      <Avatar
                        style={{
                          backgroundColor: props.isOnline ? "green" : "red",
                        }}
                        src="/static/images/avatar/1.jpg"
                        alt={user}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <button onClick={() => history.push("/login")} style={styles}>
                  Get started
                </button>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}

      {user && pageHeder.length > 0 && (
        <AppBar
          style={{ backgroundColor: "#2c3d4e" }}
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0, marginBottom: "10px" }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Tabs textColor="inherit">
                <Tab Headers={pageHeder || []} handleTabs={handleTabs} />
              </Tabs>
            </Grid>
            <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                {new Date().toDateString()}
              </Button>{" "}
              <IconClock />
            </Grid>
            <Grid item></Grid>
            <Grid item></Grid> <Grid item></Grid>
          </Grid>
        </AppBar>
      )}
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;

import { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../AppContext";

export const useApiRes = (api, options) => {
  const { refresh } = options;
  const cacheName = api.substring(api.lastIndexOf("/") + 1).split("?", 1);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [iniURL, setiniURL] = useState(null);
  const [network, setNework] = useState(null);
  const [visible, setVisible] = useState(true);
  const apiError = api.includes("undefined");
  const [fetching, setFetching] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const { setContext } = useContext(AppContext);
  const [seconds, setSeconds] = useState(0);

  // const setTimer = () => {
  //   const timer = setInterval(() => {
  //     setSeconds((prevSeconds) => {
  //       const newSeconds = prevSeconds + 1;
  //       if (newSeconds >= 10) {
  //         clearInterval(timer); // Clear the timer after 10 seconds
  //         return 0; // Reset the seconds back to zero
  //       }
  //       return newSeconds;
  //     });
  //   }, 1000); // Increment the seconds every 1000 milliseconds (1 second)

  //   // Clean up the timer on component unmount
  //   return () => {
  //     clearInterval(timer);
  //   };
  // };

  // const addDataIntoCache = (response) => {
  //   const data = new Response(JSON.stringify(response));

  //   if ("caches" in window) {
  //     deleteOldCache().then((response) => {
  //       setTimeout(() => {
  //         caches.open(cacheName[0]).then((cache) => {
  //           cache.put(api, data);
  //         });
  //       }, 10);
  //     });
  //   }
  // };

  // const queryCache = async () => {
  //   var url = [];
  //   caches.open(cacheName).then(function (cache) {
  //     cache
  //       .keys()
  //       .then(function (keys) {
  //         return Promise.all(
  //           keys.map(function (k) {
  //             url.push(k.url);
  //             return k.url;
  //           })
  //         );
  //       })
  //       .then(function (u) {
  //         cacheList(url.slice(-1));
  //       });
  //   });
  // };

  // const deleteOldCache = async () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       name === cacheName[0] && caches.delete(name);
  //     });
  //   });
  //   return;
  // };

  // function cacheList(Items) {
  //   for (var i = 0; i < Items?.length; i++) {
  //     setiniURL(Items[i]);
  //   }
  // }

  // console.log("seconds", seconds);

  // useEffect(() => {
  //   getData();
  //   setTimer();
  //   // Cancel all previous calls when a new request is made
  //   if (currentRequest || seconds > 0) {
  //     currentRequest.abort();
  //     console.log(currentRequest.abort());
  //   }

  //   const controller = new AbortController();
  //   setCurrentRequest(controller);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [iniURL]);

  // const getData = async () => {
  //   if (typeof caches === "undefined") return false;
  //   const cacheStorage = await caches.open(cacheName[0]);
  //   const cachedResponse = await cacheStorage.match(iniURL);
  //   if (!cachedResponse || !cachedResponse.ok) {
  //     setError("Fetched failed!");
  //   }
  //   return cachedResponse?.json().then((item) => {
  //     setData(item.errors && data.length !== 0 ? [] : item);
  //   });
  // };

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    setNework(navigator.onLine);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    // setContext((prevContext) => ({
    //   ...prevContext,
    //   fetching: true, // Set fetching status to true when making the API call
    // }));
    const fetchData = async () => {
      if (!apiError) {
        try {
          const response = await fetch(api, {
            signal: abortController.signal,
          });
          setContext((prevContext) => ({
            ...prevContext,
            fetching: true,
            fetchError: false, // Set fetching status to false after the API call
          }));
          const data = await response.json();
          // !apiError && addDataIntoCache(data);
          !apiError && setData(data);
          // setLoading(false);
          // setFetching(false);
          setContext((prevContext) => ({
            ...prevContext,
            fetching: false, // Set fetching status to false after the API call
          }));
        } catch (error) {
          console.log(error);
          if (error.name !== "AbortError") {
            setContext((prevContext) => ({
              ...prevContext,
              fetching: false,
              fetchError: error + ". Showing last cached data", // Set fetching status to false after the API call
            }));
          }
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, network, visible, location, refresh]);

  return { data, error, loading: loading || fetching };
};

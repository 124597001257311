import { EncryptStorage } from "encrypt-storage";
import moment from "moment";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApiRes } from "./useApiRes";
import { AddToApi, saveToIndexedDB } from "./IndexDB/PostData";
import useAsyncSubmit from "./PostData";
import { accountingFormat } from "../components/common/Functions";

// const API = axios.create({ baseURL: 'http://localhost:5000'})
// const Main_URL = "https://api.crato.co.ke/api/";
const Main_URL = "http://styx.crato.co.ke/api/";
const Funguo = "LaHNcG1xClzAqZpegrr83gbxmSkZsniZ";
const isOnline = localStorage.getItem("online");
const chatgptkey = "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";
//colors
//green- 44dc94
//dark blue - 2c3d4e
//white -7fa7ba
//grey - #7fa7ba;

export function SendSMS(data) {
  return fetch(`https://wakish.esquekenya.com/api/Company/SendMessage`, {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      PhoneNumber: data.PhoneNumber,
      Message: data.Message,
    }),
  });
}

export const encryptStorage = new EncryptStorage("secret-key", {
  encAlgorithm: "Rabbit",
});

export const encryptSession = new EncryptStorage("secret-key", {
  storageType: "sessionStorage",
});

const VendorID =
  encryptSession.getItem("ci") === undefined ? 0 : encryptSession.getItem("ci");
const UserID = encryptSession.getItem("us");

export function UserLogin(user) {
  return fetch(Main_URL + "Users/UserLogin", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      PhoneNumber: user.email,
      Password: user.password,
    }),
  });
}

export function GetPassCode(phone) {
  return fetch(Main_URL + "Users/RequestPasscode", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      phoneNumber: phone,
    }),
  });
}

export function ResetPassword(data) {
  return fetch(Main_URL + "Users/ConfirmResetPassword", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      resetCode: data.PassCode,
      password: data.NewPassword,
      phoneNumber: data.PhoneNumber,
    }),
  });
}

export function UserLoginCustomer(user) {
  return fetch(Main_URL + "Users/LoginCustomerUser", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      PhoneNumber: user.email,
      Password: user.password,
    }),
  });
}

export function AddCompanyLocation(data, ProviderID) {
  const address = localStorage.getItem("address");
  const lat = localStorage.getItem("lat");
  const lng = localStorage.getItem("lng");
  return fetch(Main_URL + "AddProviderServiceLocation", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      ProviderID: ProviderID,
      CityID: data.CityID,
      LocationName: data.locationName,
      ContactPhone: data.ContactPhone,
      Area: address,
      Street: address,
      ProviderImage: 0,
      Latitude: lat,
      Longitude: lng,
      AddedBy: 0,
    }),
  });
}

export function UserRegistration(data) {
  return fetch(Main_URL + "Admin/VendorUserRegistration", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      UserID: 0,
      VendorID: 0,
      VendorLocationID: 0,
      AddedBy: 0,
      UserTypeID: 0,
      FirstName: data.firstName,
      LastName: data.lastName,
      PhoneNumber: data.phoneNumber,
      Email: data.email,
      UserImage: data.logo,
    }),
  });
}

export function UserCustomerRegistration(data) {
  return fetch(Main_URL + "Customer/VendorCustomerUserRegistration", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      userID: 0,
      customerID: data.CustomerID,
      addedBy: UserID,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      userImage: 0,
    }),
  });
}

export const GetNozzleReadingType = (refresh) => {
  const { data: readingtype, error } = useApiRes(
    Main_URL + `Admin/GetNozzleReadingType`,
    { refresh }
  );
  return { readingtype, error };
};

export const GetLocations = (refresh) => {
  const { data: locations, error } = useApiRes(
    Main_URL + `Admin/GetVendorLocation?VendorID=${VendorID}`,
    { refresh }
  );
  return { locations, error };
};

export const GetTank = (LocationID, refresh) => {
  const { data: tank, mutate: gettank } = useApiRes(
    Main_URL +
      `Stock/GetVendorTank?VendorID=${VendorID}&VendorLocationID=${LocationID}`,
    { refresh }
  );
  return { tank, gettank };
};

export function AddVendorTank(data) {
  return fetch(Main_URL + "Stock/AddVendorTank", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      ProductID: data.ProductID,
      TankName: data.TankName,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
      TankCapacity: data.TankCapacity,
      AddedBy: UserID,
      TankParentID: data.TankParentID,
    }),
  });
}

export function UpdateVendorTank(data) {
  return fetch(Main_URL + "Stock/UpdateVendorTank", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      ProductID: data.ProductID,
      TankID: data.TankID,
      TankName: data.TankName,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
      TankCapacity: data.TankCapacity,
      AddedBy: UserID,
      TankParentID: data.TankParentID,
    }),
  });
}

export function RemoveVendorTank(data) {
  return fetch(Main_URL + "Stock/RemoveVendorTank", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      TankID: data.TankID,
      AddedBy: UserID,
    }),
  });
}

export const GetDipReading = (TankID, refresh) => {
  const { data: reading, error } = useApiRes(
    Main_URL + `Stock/GetVendorTankDip?TankID=${TankID}`,
    { refresh }
  );
  return { reading, error };
};

export const GetNozzle = (PumpID, refresh) => {
  const { data: nozzle, error } = useApiRes(
    Main_URL + `Stock/GetVendorPumpNozzle?PumpID=${PumpID}`,
    { refresh }
  );
  return { nozzle, error };
};

export const GetVendorLocationNozzle = (VendorLocationID, refresh) => {
  const { data: nozzle, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorPumpNozzles?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}`,
    { refresh }
  );
  return { nozzle, error };
};

export const GetNozzleReading = (DateRange, PumpID, refresh) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: reading, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorPumpNozzleReading?PumpID=${PumpID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { reading, error };
};

export function AddDipReading(data) {
  return fetch(Main_URL + "Stock/PostDipReading", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorID: VendorID,
      ShiftClockNo: data.ShiftClockNo,
      VendorLocationID: data.VendorLocationID,
      AddedBy: UserID,
      TankParentArray: data.dip,
    }),
  });
}

export function AddSingleDipReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostNewDipReading";
  const requestBody = {
    addedBy: UserID,
    shiftClockNO: data.ShiftClockNO,
    shiftRefNO: data.ShiftRefNO,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    clockDate: data.ClockDate,
    tankParentArray: data.TankParentArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateSecondDipReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateBackdatedClosedParentDipReading";
  const requestBody = {
    parentDipNO: data.ParentDipNO,
    addedBy: UserID,
    tankDipArray: [
      {
        dipNO: data.DipNO,
        secondReading: data.NewReading,
      },
    ],
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function CloseShift(data) {
  return fetch(Main_URL + "Stock/CloseTankPumpNozzleReading", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      ShiftID: data.ShiftID,
      ClosingReading: data.ClosingReading,
      ClosedBy: UserID,
      VendorTankParentNozzleID: data.VendorTankParentNozzleID,
    }),
  });
}

export function OpenShift(data) {
  return fetch(Main_URL + "Stock/OpenTankPumpNozzleReading", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      OpeningReading: data.OpeningReading,
      AddedBy: UserID,
      PumpID: data.PumpID,
      NozzleID: data.NozzleID,
      VendorTankParentNozzleID: data.VendorTankParentNozzleID,
    }),
  });
}

export const GetPump = (LocationID, refresh) => {
  const { data: pump, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorLocationPump?VendorLocationID=${LocationID}&VendorID=${VendorID}`,
    { refresh }
  );
  return { pump, error };
};

export function AddVendorPump(data) {
  return fetch(Main_URL + "Stock/AddVendorLocationPump", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      PumpName: data.PumpName,
      AddedBy: UserID,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
    }),
  });
}

export function UpdateVendorPump(data) {
  return fetch(Main_URL + "Stock/UpdateVendorLocationPump", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      PumpID: data.PumpID,
      PumpName: data.PumpName,
      AddedBy: UserID,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
    }),
  });
}

export function RemoveVendorPump(data) {
  return fetch(Main_URL + "Stock/RemoveVendorLocationPump", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      PumpID: data.PumpID,
      AddedBy: UserID,
    }),
  });
}

export const GetProduct = (VendorLocationID, ProductCategoryID, refresh) => {
  const { data: product, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorLocationProduct?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&ProductCategoryID=${ProductCategoryID}`,
    { refresh }
  );
  return { product, error };
};

export const GetClockProduct = (
  VendorLocationID,
  ClockNO,
  ProductCategoryID,
  refresh
) => {
  const { data: product, error } = useApiRes(
    Main_URL +
      `Stock/GetAttendantLocationProduct?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&ClockNO=${ClockNO}&ProductCategoryID=${ProductCategoryID}`,
    { refresh }
  );
  return { product, error };
};

export const GetCustomer = (VendorLocationID, refresh) => {
  const { data: customer, error } = useApiRes(
    Main_URL +
      `Customer/GetVendorCustomers?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { customer, error };
};

export const GetAllCustomerStatement = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const {
    data: customer,
    error,
    loading,
  } = useApiRes(
    Main_URL +
      `Customer/GetVendorAllCustomersSatement?VendorID=${start}&VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { customer, error, loading };
};

export const GetCustomerExpense = (CustomerID, refresh) => {
  const { data: expense, error } = useApiRes(
    Main_URL + `Finance/GetVendorCustomerExpense?CustomerID=${CustomerID}`,
    { refresh }
  );
  return { expense, error };
};

export function AddVendorProduct(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/AddVendorProduct";
  const requestBody = {
    productName: data.ProductName,
    uom: "",
    productImageID: data.base64,
    addedBy: UserID,
    vendorID: VendorID,
    buyingTax: data.TaxRate,
    IsStockItem: data.IsStockItem ? 1 : 0,
    sellingTax: data.TaxRate,
    productCategoryID: data.ProductCategoryID,
    productCategoryBrandID: data.ProductCategoryBrandID,
    productVolumeSizeID: data.ProductVolumeSizeID,
    productCategoryTypeID: data.ProductCategoryTypeID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetSalesHistory = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: sales, loading } = useApiRes(
    Main_URL +
      `Sales/GetSaleHistory?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { sales, loading };
};

export const GetProductDailySales = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: sales, loading } = useApiRes(
    Main_URL +
      `SalesReport/GetProductDailySales?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { sales, loading };
};

export const GetVendorLocationDailyDIscount = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: discount, loading } = useApiRes(
    Main_URL +
      `FinanceReport/GetVendorLocationDailyDiscount?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { discount, loading };
};

export const GetDailyDipVariance = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: variance, loading } = useApiRes(
    Main_URL +
      `StockReport/GetVendorDailyTotalDipVariance?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { variance, loading };
};

export const GetProductSalesReport = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: sales, loading } = useApiRes(
    Main_URL +
      `SalesReport/GetProductSales?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { sales, loading };
};

export const GetInvoiceCustomerSalesHistory = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: invoice, loading } = useApiRes(
    Main_URL +
      `Sales/GetVendorCustomerInvoiceSales?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { invoice, loading };
};

export const GetAttendantClockSales = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: sales, loading } = useApiRes(
    Main_URL +
      `SalesReport/GetAttendantClockSales?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { sales, loading };
};

export function AddVendorSale(data) {
  return fetch(Main_URL + "Finance/AddVendorPaymentMethod", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      userID: 0,
      clockNO: "string",
      vendorID: 0,
      vendorLocationID: 0,
      customerID: 0,
      assetID: 0,
      totalAmount: 0,
      saleID: "string",
      saleDetailArray: data.saleDetail,
      salePaymentArray: data.receipt,
    }),
  });
}

export function AddVendorClock(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/AddVendorShiftUserClock";
  const requestBody = {
    addedBy: UserID,
    clockTypeID: data.ClockTypeID,
    shiftClockNO: data.ShiftClockNO,
    shiftRefNO: data.ShiftRefNO,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    clockDate: data.ClockDate,
    userClockArray: data.UserClockArray,
    accountBalanceArray: [],
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ApproveVendorShift(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/ApproveShiftClock";
  const requestBody = {
    shiftClockNO: data.ShiftClockNO,
    vendorLocationID: data.VendorLocationID,
    approvedBy: UserID,
    statusID: 2,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateMpesaStartTime(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateShiftMpesaStartTime";
  const requestBody = {
    shiftRefNO: data.ShiftRefNO,
    mpesaStartTime: data.MpesaStartTime,
    mpesaStartAmount: data.MpesaAmount,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ApproveVendorClock(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/ApproveUserClock";
  const requestBody = {
    clockNO: data.ClockNO,
    approvedBy: UserID,
    VendorLocationID: data.VendorLocationID,
    statusID: 2,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddVendorShift(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.ClockDate).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/AddVendorShift";
  const requestBody = {
    addedBy: UserID,
    clockTypeID: data.ClockTypeID,
    MpesaStartTime: data.MpesaStartTime,
    dipStatusID: data.DipStatusID,
    dipVarianceReason: data.DipVarianceReason,
    mpesaStartAmount: data.MpesaStartAmount,
    shiftClockNO: data.ShiftClockNO,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    clockDate: `${passedDate} ${currentTime}`,
    userClockArray: data.UserClockArray,
    tankParentArray: data.TankParentArray,
    accountBalanceArray: data.accounts,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function CloseVendorShift(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.ClockDate).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/CloseVendorShift";
  const requestBody = {
    addedBy: UserID,
    shiftClockNO: data.shiftClockNO,
    shiftRefNO: data.shiftRefNO,
    vendorLocationID: data.vendorLocationID,
    vendorID: VendorID,
    StatusID: data.StatusID,
    userClockArray: data.userClockArray,
    tankParentArray: data.tankParentArray,
    accountBalanceArray: data.accountBalanceArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorUser = (VendorLocationID, refresh) => {
  const { data: users, error } = useApiRes(
    Main_URL +
      `Admin/GetVendorUsers?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { users, error };
};

export const GetVendorGeneralSalesTax = (
  VendorLocationID,
  TaxDate,
  refresh
) => {
  const { data: salestax, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetGeneratedTax?VendorLocationID=${VendorLocationID}&TaxDate=${TaxDate}`,
    { refresh }
  );
  return { salestax, error };
};

export const GetVendorWithHoldingTax = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: wtax, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorWithholdingInvoice?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { wtax, error };
};

export const GetVendorGeneralPurchaseTax = (
  VendorLocationID,
  TaxDate,
  refresh
) => {
  const { data: ptax, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetGeneralPurchaseTax?VendorLocationID=${VendorLocationID}&TaxDate=${TaxDate}`,
    { refresh }
  );
  return { ptax, error };
};

export function UpdateInvoiceWithholdingTax(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateWithholdingTaxInvoice";
  const requestBody = {
    saleIDs: data?.Sales,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    result: asyncSubmit.result,
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorClockFreeUser = (VendorLocationID, refresh) => {
  const { data: users, error } = useApiRes(
    Main_URL +
      `Admin/GetVendorClockFreeUsers?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { users, error };
};

export function AddVendorUserRole(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/PostUserPolicyAssignment";
  const requestBody = {
    userID: data.UserID,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
    userPolicyArray: data.UserPolicyArray,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    result: asyncSubmit.result,
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RemoveVendorUserRole(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/RemoveUserPolicyAssignment";
  const requestBody = {
    userID: data.UserID,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
    userPolicyArray: data.UserPolicyArray,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    result: asyncSubmit.result,
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddVendorUser(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/VendorUserRegistration";
  const requestBody = {
    UserID: 0,
    VendorID: VendorID,
    VendorLocationID: data.VendorLocationID,
    AddedBy: UserID,
    FirstName: data.FirstName,
    LastName: data.LastName,
    PhoneNumber: data.PhoneNumber,
    UserTypeID: data.UserTypeID,
    canSell: data.CanSell,
    Email: data.Email,
    UserImage: 0,
    stationsAssignmentArray: data.StationArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    result: asyncSubmit.result,
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateVendorUser(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/UpdateVendorUser";
  const requestBody = {
    userID: data.UserID,
    firstName: data.FirstName,
    lastName: data.LastName,
    phoneNumber: data.PhoneNumber,
    email: data.Email,
    addedBy: UserID,
    stationsAssignmentArray: data?.StationArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    result: asyncSubmit.result,
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RemoveVendorUser(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/RemoveVendorUser";
  const requestBody = {
    VendorLocationID: data.VendorLocationID,
    VendorUserID: data.VendorUserID,
    AddedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorClock = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: clocks, error } = useApiRes(
    Main_URL +
      `Supervisor/GetVendorUserClock?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { clocks, error };
};

export const GetVendorShifts = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: shifts, error } = useApiRes(
    Main_URL +
      `Supervisor/GetShiftClockSummary?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { shifts, error };
};

export const GetVendorShiftsAdminACtions = (values, refresh) => {
  const { DateRange, VendorLocationID, user } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: actions, error } = useApiRes(
    Main_URL +
      `AdminReport/GetAdminAction?StartDate=${start}&EndDate=${end}&VendorID=${VendorID}&UserID=${user}`,
    { refresh }
  );
  return { actions, error };
};

export const GetVendorDailyNozzleReadingSummary = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: MeterReading, error } = useApiRes(
    Main_URL +
      `Supervisor/GetVendorDailySummaryNozzleReading?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { MeterReading, error };
};

export const GetVendorDailyShiftSummary = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: daily, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetShiftSummaryDailyClocks?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { daily, error };
};

export const GetVendorShiftsDetails = (
  VendorLocationID,
  ShiftClockNO,
  refresh
) => {
  const { data: details, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetShiftClockDetail?VendorLocationID=${VendorLocationID}&ShiftClockNO=${ShiftClockNO}`,
    { refresh }
  );
  return { details, error };
};

export const GetVendorDailyShiftsDetails = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: shifts, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetShiftSummaryDailyClocks?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { shifts, error };
};

export const GetUserType = (refresh) => {
  const { data: types, error } = useApiRes(Main_URL + `Admin/GetUserType`, {
    refresh,
  });
  return { types, error };
};

export const GetVendorStorageLocation = (VendorLocationID, refresh) => {
  const { data: stores, error } = useApiRes(
    Main_URL +
      `Admin/GetVendorStorageLocation?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}`,
    { refresh }
  );
  return { stores, error };
};

export function AddStorageLocation(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/AddVendorStorageLocation";
  const requestBody = {
    VendorStorageLocationID: 0,
    VendorID: VendorID,
    AddedBy: UserID,
    ProductCategoryID: data.ProductCategoryID,
    StorageLocationName: data.StorageLocationName,
    VendorLocationID: data.VendorLocationID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateVendorStorageLocation(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/UpdateVendorStorageLocation";
  const requestBody = {
    VendorStorageLocationID: data.VendorStorageLocationID,
    VendorID: VendorID,
    AddedBy: UserID,
    ProductCategoryID: data.ProductCategoryID,
    StorageLocationName: data.StorageLocationName,
    VendorLocationID: data.VendorLocationID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RemoveVendorStorageLocation(data) {
  return fetch(Main_URL + "Admin/UpdateVendorStorageLocation", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorStorageLocationID: data.VendorStorageLocationID,
      AddedBy: UserID,
    }),
  });
}

export function AddStockMovement(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostStockMovementTx";
  const requestBody = {
    VendorID: VendorID,
    shiftClockNO: data.ShiftClockNO,
    VendorLocationID: data.VendorLocationID,
    movementTypeID: data.MovementTypeID,
    SourceLocationID: data.SourceLocationID || 0,
    DestinationLocationID: data.DestinationLocationID || 0,
    AddedBy: UserID,
    StockNO: 0,
    MonthlyBalance: data.MonthlyBalance,
    StockMovementDetailArray: data.stocks,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddStockTakeItem(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostStockTakeItem";
  const requestBody = {
    stockNO: data.StockNO,
    productID: data.ProductID,
    quantity: data.Quantity,
    previousQuantity: 0,
    dateAdded: data.StockDate,
    stockDate: data.StockDate,
    statusID: 1,
    addedBy: UserID,
    productPrice: data.ProductPrice,
    PurchasePrice: data.PurchasePrice,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateStockMovement(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateStockMovement";
  const requestBody = {
    stockNO: data.StockNO,
    stockDate: data.StockDate,
    monthlyBalance: data.MonthlyBalance,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddStockTransfer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostStockTransferTx";
  const requestBody = {
    addedBy: UserID,
    vendorID: VendorID,
    sourceLocationID: data.VendorLocationID,
    transferInfo: "",
    transferNO: "",
    transferDetailArray: data.stocks,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DeleteStockTransfer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/DeleteStockTransfer";
  const requestBody = {
    addedBy: UserID,
    transferNO: data.TransferNO,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateStockTakeItem(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateStockTakeItem";
  const requestBody = {
    ProductID: 0,
    Quantity: data.Quantity,
    PreviousQuantity: data.PreviousQuantity,
    PurchasePrice: data.PurchasePrice,
    StockDate: data.DateAdded,
    AddedBy: UserID,
    StockMovementDetailID: data.StockMovementDetailID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetStockMovement = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: movement, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorStockMovement?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { movement, error };
};

export const GetStockMovementDetail = (StockNO, refresh) => {
  const { data: movement, error } = useApiRes(
    Main_URL + `Stock/GetVendorStockMovementDetail?StockNO=${StockNO}`,
    { refresh }
  );
  return { movement, error };
};

export const GetStockTransfer = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: transfer, error } = useApiRes(
    Main_URL +
      `Stock/GetStockTransfer?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDAte=${end}`,
    { refresh }
  );
  return { transfer, error };
};

export const GetStocInboundkTransfer = (VendorLocationID, refresh) => {
  const { data: inbound, error } = useApiRes(
    Main_URL +
      `Stock/GetStockInTransfer?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { inbound, error };
};

export function ReceiveTransfer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/ReceiveStockTransferDetail";
  const requestBody = {
    stockTransferDetailID: data.StockTransferDetailID,
    stockInTankID: data.StockInTankID,
    quantity: data.ReceivedQuantity,
    receivedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddProductPrice(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/SaveProductPrice";
  const requestBody = {
    VendorID: VendorID,
    VendorLocationID: data.VendorLocationID,
    ProductID: data.ProductID,
    Amount: data.ProductPrice,
    AddedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddCashCollection(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/SaveVendorPaymentCollection";
  const requestBody = {
    VendorID: VendorID,
    VendorLocationID: data.VendorLocationID,
    VendorPaymentMethodID: +data.VendorPaymentMethodID,
    CashDropCollectionID: 0,
    ClockNO: data.ClockNO,
    CollectionNO: 0,
    CollectedAmount: +data.Amount,
    AddedBy: UserID,
    CollectionTypeID: 1,
    Comment: data.Comment || "",
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddClockCashDrop(data) {
  return fetch(Main_URL + "Finance/SaveClockCashDrop", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorID: VendorID,
      vendorPaymentMethodID: data.VendorPaymentMethodID,
      vendorLocationID: data.VendorLocationID,
      clockNO: data.ClockNO,
      amount: data.Amount,
      addedBy: UserID,
      cashDropTypeID: 0,
      refNO: data.Comment,
    }),
  });
}

export function UpdateCashDrop(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateClockCashDrop";
  const requestBody = {
    VendorCashDropID: data.VendorCashDropID,
    Amount: data.Amount,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetCashDrop = (ClockNO, refresh) => {
  const { data: drops, error } = useApiRes(
    Main_URL + `Finance/GetClockCashDrop?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { drops, error };
};

export const GetClockSummary = (ClockNO, refresh) => {
  const { data: summary, error } = useApiRes(
    Main_URL + `Sales/GetClockSaleSummaryDashBoard?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { summary, error };
};

export const GetVendorSalesDashboard = (VendorLocationID, refresh) => {
  const { data: summary, error } = useApiRes(
    Main_URL +
      `SalesReport/GetSalesDashBoardReport?VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { summary, error };
};

export const GetVendorClockRTT = (ClockNO, refresh) => {
  const { data: rtt, error } = useApiRes(
    Main_URL + `Stock/GetClockRTT?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { rtt, error };
};

export const GetDailySHiftRTTDetail = (
  ClockDate,
  VendorLocationID,
  refresh
) => {
  const { data: rtt, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorShiftDailyDetailRTT?ClockDate=${ClockDate}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { rtt, error };
};

export function ReverseClockRTT(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/ReverseClockRTT";
  const requestBody = {
    vendorRttID: data.VendorRTTID,
    clockNO: data.ClockNO,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ApproveClockRTT(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/ApproveClockRTT";
  const requestBody = {
    vendorRttID: data.VendorRTTID,
    clockNO: data.ClockNO,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddClockRTT(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/AddVendorClockRTT";
  const requestBody = {
    clockNO: data.ClockNO,
    vendorTankParentNozzleID: data.VendorTankParentNozzleID,
    shiftNO: data.ShiftNO,
    totalAmount: data.TotalAmount,
    productPrice: data.ProductPrice,
    productID: data.ProductID,
    tankID: data.TankID,
    addedBy: UserID,
    rttReason: data.RTTReason,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetShiftTransfer = (ClockNO, refresh) => {
  const { data: summary, error } = useApiRes(
    Main_URL + `Sales/GetClockSaleSummaryDashBoard?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { summary, error };
};

export const GetClockPumps = (ClockNO, VendorLocationID, refresh) => {
  const { data: summary, error } = useApiRes(
    Main_URL +
      `Supervisor/GetVendorLocationClockPump?VendorLocationID=${VendorLocationID}&ClockNO=${ClockNO}`,
    { refresh }
  );
  return { summary, error };
};

export const GetCashDropCollection = (ClockNO, refresh) => {
  const { data: collection, error } = useApiRes(
    Main_URL + `Finance/GetUserClockPayment?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { collection, error };
};

export const GetDropCollection = (ClockNO, refresh) => {
  const { data: collection, error } = useApiRes(
    Main_URL + `Finance/GetClockPaymentCollection?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { collection, error };
};

export const GetClockMpesaCollection = (ClockNO, refresh) => {
  const { data: mpesa, error } = useApiRes(
    Main_URL + `Finance/GetUserClockPayment?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { mpesa, error };
};

export function GetPayments(values, refresh) {
  const controller = "Finance/GetPaymentTransAction";
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const url = `?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`;
  const {
    data: payment,
    loading: isLoading,
    // getData,
  } = useApiRes(Main_URL + controller + url, { refresh });

  return { payment, isLoading };
}

export const GetUnusedPayments = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: payment, error } = useApiRes(
    Main_URL +
      `TransAction/GetVendorUnusedAdminTransAction?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { payment, error };
};

export const GetMarkedPayments = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: markedlist, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetMarkedTransAction?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );

  return { markedlist, error };
};

export const GetParentTanks = (refresh) => {
  const { data: parent, error } = useApiRes(Main_URL + `Stock/GetTankParent`, {
    refresh,
  });
  return { parent, error };
};

export function AddTankNozzle(data) {
  return fetch(Main_URL + "Stock/AddVendorTankParentNozzle", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      TankParentID: data.TankID,
      AddedBy: UserID,
      NozzleID: data.NozzleID,
    }),
  });
}

export function CloseClock(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/CloseUserClock";
  const requestBody = {
    ClockNO: data.ClockNO,
    varianceReason: data.VarianceReason,
    ExpectedAmount: data.TotalAmount,
    accountedAmount: data.AccountedAmount,
    varianceAmount: data.VarianceAmount,
    ClockedOutBy: UserID,
    StatusID: data.StatusID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReopenClock(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/ReOpenUserClock";
  const requestBody = {
    ClockNO: data.ClockNO,
    AddedBy: UserID,
    VendorLocationID: data.VendorLocationID,
    clockDate: new Date(data.ClockDate),
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetPumpNozzles = (VendorLocationID, refresh) => {
  const { data: nozzles, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorLocationPumpNozzle?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}`,
    { refresh }
  );
  return { nozzles, error };
};

export function AddPumpNozzle(data) {
  return fetch(Main_URL + "Stock/AddVendorLocationPumpNozzle", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      NozzleName: data.NozzleName,
      PumpID: data.PumpID,
      AddedBy: UserID,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
      TankParentID: data.TankParentID,
    }),
  });
}

export function UpdatePumpNozzle(data) {
  return fetch(Main_URL + "Stock/UpdateVendorTankParentNozzle", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      NozzleID: data.NozzleID,
      VendorTankParentNozzleID: data.VendorTankParentNozzleID,
      NozzleName: data.NozzleName,
      PumpID: data.PumpID,
      AddedBy: UserID,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
      TankParentID: data.TankParentID,
    }),
  });
}

export function RemovePumpNozzle(data) {
  return fetch(Main_URL + "Stock/RemoveVendorLocationPumpNozzle", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      NozzleID: data.NozzleID,
      AddedBy: UserID,
    }),
  });
}

export const GetTankNozzle = (TankID, refresh) => {
  const { data: nozzles, error } = useApiRes(
    Main_URL + `Stock/GetVendorTankPumpNozzle?TankID=${TankID}`,
    { refresh }
  );
  return { nozzles, error };
};

export function RemoveAssignment(NozzleID) {
  return fetch(Main_URL + "Stock/RemoveVendorLocationTankNozzle", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorTankNozzleID: NozzleID,
      StatusID: 2,
    }),
  });
}

export function PostVendorCustomer(data, successFn, errorFn) {
  console.log("data", data);
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/PostVendorCustomer";
  const requestBody = {
    customerName: data.CustomerName,
    customerPhone: data.CustomerPhone,
    customerMail: data.CustomerMail,
    kraPin: data.KRAPin,
    openingBalance: data.OpeningBalance,
    creditLimit: data.CreditLimit,
    paymentTerms: data.PaymentTerms,
    addedBy: UserID,
    vendorID: VendorID,
    isPrepayCustomer: data.isPrepayCustomer,
    creditLimitNotification: data.CreditLimits,
    isCreditLimitMandatory: data.IsCreditLimitMandatory,
    fuelingLocationArray: data.StationArray,
    customerAssetsArray: data.Asset,
    CustomerTypeID: data.CustomerTypeID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddCustomer(data) {
  return fetch(Main_URL + "Customer/AddVendorCustomer", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      CustomerName: data.CustomerName,
      CustomerPhone: data.CustomerPhone,
      CustomerMail: data.CustomerMail,
      OpeningBalance: data.OpeningBalance,
      CreditLimit: data.CreditLimit,
      PaymentTerms: data.PaymentTerms,
      KRAPin: data.KRAPin,
      AddedBy: UserID,
      VendorLocationID: data.VendorLocationID,
      VendorID: VendorID,
      IsCreditLimitMandatory: data.IsCreditLimitMandatory,
      CreditLimitNotification: data.CreditLimitNotification,
    }),
  });
}

export function DeleteCustomer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/RemoveVendorCustomer";
  const requestBody = {
    customerID: data.CustomerID,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddAsset(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/AddVendorCustomerAsset";
  const requestBody = {
    customerID: data.VendorCustomerID,
    assetName: data.AssetName,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateCustomer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/UpdateVendorCustomer";
  const requestBody = {
    OpeningBalance: data.OpeningBalance,
    prevOpeningBalance: data.PrevOpeningBalance,
    CustomerID: data.CustomerID,
    CustomerName: data.CustomerName,
    CustomerPhone: data.CustomerPhone,
    CustomerMail: data.CustomerMail,
    CreditLimit: data.CreditLimit,
    IsCreditLimitMandatory: data.IsCreditLimitMandatory,
    KRAPin: data.KRAPin,
    CreditLimitNotification: data.CreditLimitNotification,
    PaymentTerms: data.PaymentTerms,
    isWithholding: data.IsWithholding,
    isTemporary: data.IsTemporary,
    AddedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddCustomerAssets(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/AddVendorCustomerAsset";
  const requestBody = {
    CustomerID: data.CustomerID,
    AssetName: data.AssetName,
    AddedBy: UserID,
    IsDelivery: data.IsDelivery,
    IsAssetStation: data.IsAssetStation,
    StationID: data.StationID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RemoveCustomerAssets(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/RemoveVendorCustomerAsset";
  const requestBody = {
    assetID: data.AssetID,
    AddedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddVendorLocation(data) {
  return fetch(Main_URL + "Admin/AddVendorLocation", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorLocationID: 0,
      VendorID: VendorID,
      AddedBy: UserID,
      NozzleReadingTypeID: data.NozzleReadingTypeID,
      VendorLocationName: data.VendorLocationName,
      ContactPerson: data.ContactPerson,
      VendorLocationPhone: data.VendorLocationPhone,
      TradingName: data.TradingName,
      isStockMandatory: data.isMandatory ? 1 : 0,
      Slogan: "",
    }),
  });
}

export function UpdateVendorLocation(data) {
  return fetch(Main_URL + "Admin/UpdateVendorLocation", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorLocationID: 0,
      VendorID: VendorID,
      AddedBy: UserID,
      NozzleReadingTypeID: data.NozzleReadingTypeID,
      VendorLocationName: data.LocationName,
      ContactPerson: data.ContactPerson,
      VendorLocationPhone: data.VendorLocationPhone,
      TradingName: data.TradingName,
      Slogan: data.Slogan,
    }),
  });
}

export const GetProductCategory = (refresh) => {
  const { data: category, error } = useApiRes(
    Main_URL + `Stock/GetProductCategory`,
    { refresh }
  );
  return { category, error };
};

export const GetVendorPaymentMethod = (VendorLocationID, refresh) => {
  const { data: methods, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorPaymentMethod?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { methods, error };
};

export function AddVendorSupplierPayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorSupplierTopUp";
  const requestBody = {
    vendorSupplierID: data.SupplierID,
    vendorPaymentMethodID: data.VendorPaymentMethodID,
    referenceNO: data.ReferenceNO,
    topUpAmount: data.TotalAmount,
    addedBy: UserID,
    vendorID: VendorID,
    transactionDate: data.TransactionDate,
    vendorLocationID: data.VendorLocationID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorPaymentMethodBalances = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: methods, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetPaymentMethodBalances?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { methods, error };
};

export function AddVendorPaymentMethod(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorPaymentMethod";
  const requestBody = {
    vendorPaymentMethodID: 0,
    vendorPaymentMethodName: data.VendorPaymentMethodName,
    paymentMethodID: data.PaymentMethodID,
    openingBalance: data.OpeningBalance,
    holdingLimit: data.HoldingLimit,
    referenceNO: "",
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
    isVisibleToAttendant: data.IsVisibleToAttendant,
    isHashed: data.IsHashed,
    isRecon: data.IsRecon,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateVendorPaymentMethod(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateVendorPaymentMethod";
  const requestBody = {
    vendorPaymentMethodID: data.VendorPaymentMethodID,
    vendorPaymentMethodName: data.VendorPaymentMethodName,
    paymentMethodID: data.PaymentMethodID,
    openingBalance: data.OpeningBalance,
    holdingLimit: data.HoldingLimit,
    referenceNO: "",
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
    isVisibleToAttendant: data.IsVisibleToAttendant,
    isHashed: data.IsHashed,
    isRecon: data.IsRecon,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RemoveVendorPaymentMethod(data) {
  return fetch(Main_URL + "Finance/RemoveVendorPaymentMethod", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorPaymentMethodID: data.VendorPaymentMethodID,
      AddedBy: UserID,
    }),
  });
}

export function AddPumpAssignment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/AddUserPumpAssignmentTx";
  const requestBody = {
    clockNO: data.ClockNO,
    userID: data.UserID,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
    pumpArray: [data.pumpx],
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetPumpsByClock = (VendorLocationID, ClockNO, refresh) => {
  const { data: cpumps, error } = useApiRes(
    Main_URL +
      `Stock/GetAttendantPumpNozzle?VendorLocationID=${VendorLocationID}&ClockNO=${ClockNO}`,
    { refresh }
  );
  return { cpumps, error };
};

export function RemoveAssignePump(data) {
  return fetch(Main_URL + "Supervisor/DeactivateUserPumpAssignment", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      userPumpAssignmentID: data.UserPumpAssignmentID,
      userID: UserID,
    }),
  });
}

export function ConfirmNozzleReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/AcceptTankPumpNozzleReading";
  const requestBody = {
    AddedBy: data.UserID,
    ShiftID: data.ShiftID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function OpenNozzleReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/OpenTankPumpNozzleReadings";
  const requestBody = {
    clockNO: data.ClockNO,
    vendorTankParentNozzleID: data.VendorTankParentNozzleID,
    eOpeningReading: data.EOpeningReading,
    mOpeningReading: data.MOpeningReading,
    cOpeningReading: data.COpeningReading,
    vendorLocationID: data.VendorLocationID,
    addedBy: data.UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function CloseNozzleReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/CloseTankPumpNozzleReadings";
  const requestBody = {
    shiftID: data.ShiftID,
    VendorTankParentNozzleID: data.VendorTankParentNozzleID,
    eClosingReading: data.EClosingReading,
    mClosingReading: data.MClosingReading,
    cClosingReading: data.CClosingReading,
    closedBy: data.UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ResetDipReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostDipReadingVarianceReset";
  const requestBody = {
    dipMonth: data.DipMonth,
    dipYear: data.DipYear,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddVendorPurchase(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostVendorPurchaseTx";
  const requestBody = {
    VendorID: VendorID,
    PurchaseNO: 0,
    shiftRefNO: data.ShiftRefNO,
    SupplierID: data.VendorSupplierID,
    VendorLocationID: data.VendorLocationID,
    OtherCost: data.OtherCost,
    AddedBy: UserID,
    DateAdded: `${passedDate} ${currentTime}`,
    TotalAmount: data.Subtotal + data.OtherCost,
    InvoiceNO: data.InvoiceNO,
    PurchaseDetailArray: data.rows,
    PurchasePaymentArray: data.payments,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateVendorPurchase(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data?.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateVendorPurchaseOrder";
  const requestBody = {
    vendorPurchaseID: data.VendorPurchaseID,
    supplierID: data.SupplierID,
    otherCost: data.OtherCost,
    dateAdded: `${passedDate} ${currentTime}`,
    totalAmount:
      data.DetailArray?.reduce(
        (a, b) => a + b.Quantity * b.PurchasePrice * (1 + b.BuyingTax),
        0
      ) + +data.OtherCost,
    invoiceNO: data.InvoiceNO,
    shiftRefNO: data.ShiftRefNO,
    addedBy: UserID,
    statusID: 1,
    purchaseDetailArray: data.DetailArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReceiveVendorPurchase(data) {
  return fetch(Main_URL + "Stock/ReceivePurchaseDelivery", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorPurchaseID: data.VendorPurchaseID,
      invoiceNO: data.InvoiceNO,
      addedBy: UserID,
    }),
  });
}

export function ReverseVendorPurchase(data) {
  return fetch(Main_URL + "Stock/ReversePurchase", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorPurchaseID: data.VendorPurchaseID,
      purchaseNO: data.PurchaseNO,
      invoiceNO: data.InvoiceNO,
      addedBy: UserID,
    }),
  });
}

export function AddVendorPurchasePayment(data) {
  return fetch(Main_URL + "Stock/AddPurchasePayment", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      purchaseNO: data.PurchaseNO,
      amountPaid: data.AmountPaid,
      vendorPaymentMethodID: data.VendorPaymentMethodID,
      referenceNO: data.ReferenceNO,
      addedBy: UserID,
    }),
  });
}

export const GetDashBoardData = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: dashboard, mutate } = useApiRes(
    Main_URL +
      `AdminReport/GetAdminDashBoardReport?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { dashboard, mutate };
};

export const GetVolumeSalesPerCategory = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: salescategory } = useApiRes(
    Main_URL +
      `SalesReport/GetProductCategoryDailySales?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { salescategory };
};

export const GetSalesPerCategoryPredictions = (VendorLocationID, refresh) => {
  const { data: predictions } = useApiRes(
    Main_URL +
      `SalesReport/PredictMonthlySalesByCategory?VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { predictions };
};

export const GetThisMonthDashBoardData = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: thimonth, mutate } = useApiRes(
    Main_URL +
      `AdminReport/GetAdminDashBoardReport?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { thimonth, mutate };
};

export const GetDailySalesReport = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: dsr, error } = useApiRes(
    Main_URL +
      `SalesReport/GetDailySalesReport?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { dsr, error };
};

export const GetUserSalesReport = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: udsr, error } = useApiRes(
    Main_URL +
      `SalesReport/GetUserSalesReport?StartDate=${start}&EndDate=${end}&UserID=${UserID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { udsr, error };
};

export function AddVendorSupplier(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Admin/PostVendorSupplier";
  const requestBody = {
    VendorSupplierID: 0,
    VendorID: VendorID,
    AddedBy: UserID,
    VendorSupplierName: data.SupplierName,
    ContactName: data.SupplierName,
    ContactPhone: data.ContactPhone,
    AssetMileage: data.AssetMileage,
    ContactEmail: data.ContactEmail,
    kraPin: data.KRAPin,
    OpeningBalance: data.OpeningBalance,
    supplierLocationArray: data.StationArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorSupplier = (refresh) => {
  const { data: supplier, error } = useApiRes(
    Main_URL + `Admin/GetVendorSupplier?VendorID=${VendorID}`,
    { refresh }
  );
  return { supplier, error };
};

export const GetVendorSupplierAllStatement = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: supplier, error } = useApiRes(
    Main_URL +
      `Finance/GetAllSupplierVendorStatement?VendorSupplierID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { supplier, error };
};

export const GetVendorDebtors = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: debtors, error } = useApiRes(
    Main_URL +
      `Customer/GetVendorDebtors?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { debtors, error };
};

export const GetVendorSupplierStatement = (values, refresh) => {
  const { DateRange, SupplierID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: statement, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorSupplierStatement?SupplierID=${SupplierID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { statement, error };
};

export const GetVendorSupplierPurchases = (values, refresh) => {
  const { DateRange, SupplierID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: purchase, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorSupplierPurchase?StartDate=${start}&EndDate=${end}&SupplierID=${SupplierID}`,
    { refresh }
  );
  return { purchase, error };
};

export const GetVendorSupplierPayments = (values, refresh) => {
  const { DateRange, SupplierID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: purchase, error } = useApiRes(
    Main_URL +
      `Finance/GetSupplierPayment?VendorSupplierID=${SupplierID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { purchase, error };
};

export const GetVendorCustomerCreditNote = (CustomerID, refresh) => {
  // const { DateRange, VendorLocationID } = values;
  // const start = moment(DateRange[0]).format("YYYY-MM-DD");
  // const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: creditnote, error } = useApiRes(
    Main_URL + `Finance/GetVendorCustomerCreditNote?CustomerID=${CustomerID}`,
    { refresh }
  );
  return { creditnote, error };
};

export function AddVendorCustomerCreditNote(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorCreditNote";
  const requestBody = {
    creditNoteID: 0,
    customerID: data.VendorCustomerID,
    dateAdded: `${passedDate} ${currentTime}`,
    amount: data.Amount,
    statusID: 1,
    description: data.Description,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateVendorCustomerCreditNote(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateVendorCreditNote";
  const requestBody = {
    creditNoteID: data.CreditNoteID,
    customerID: data.CustomerID,
    dateAdded: `${passedDate} ${currentTime}`,
    amount: data.Amount,
    statusID: 0,
    description: data.Description,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorCustomerDebitNote = (CustomerID, refresh) => {
  // const { DateRange, VendorLocationID } = values;
  // const start = moment(DateRange[0]).format("YYYY-MM-DD");
  // const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: creditnote, error } = useApiRes(
    Main_URL + `Finance/GetVendorCustomerDebitNote?CustomerID=${CustomerID}`,
    { refresh }
  );
  return { creditnote, error };
};

export function AddVendorCustomerDebitNote(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorDebitNote";
  const requestBody = {
    creditNoteID: 0,
    customerID: data.VendorCustomerID,
    dateAdded: `${passedDate} ${currentTime}`,
    amount: data.Amount,
    statusID: 1,
    description: data.Reason,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DeleteVendorCustomerDebitNote(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateVendorDebitNote";
  const requestBody = {
    debitNoteID: data.DebitNoteID,
    customerID: data.CustomerID,
    dateAdded: data.DateAdded,
    amount: data.Amount,
    description: data.Decription,
    statusID: 0,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddDipFuelTesting(data) {
  return fetch(Main_URL + "Stock/AddTankDipTesting", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      DipNO: data.DipNO,
      Quantity: data.Quantity,
      AddedBy: UserID,
    }),
  });
}

export const GetPurchase = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: purchase, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorPurchase?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}&VendorID=${VendorID}`,
    { refresh }
  );
  return { purchase, error };
};

export const GetProductPerfomance = (VendorLocationID, refresh) => {
  const { data: report, error } = useApiRes(
    Main_URL +
      `SalesReport/GetProductReport?VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { report, error };
};

export const GetFuelStockReport = (
  VendorLocationID,
  DateRange,
  TankParentID,
  refresh
) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: report, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorTankParentDip?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}&TankParentID=${TankParentID}`,
    { refresh }
  );
  return { report, error };
};

export const GetWetStockStockVariance = (
  VendorLocationID,
  DateRange,
  TankParentID,
  refresh
) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: report, error } = useApiRes(
    Main_URL +
      `StockReport/GetVendorWetSTockVariance?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}&TankParentID=${TankParentID}`,
    { refresh }
  );
  return { report, error };
};

export const GetVendorTankParent = (VendorLocationID, refresh) => {
  const { data: parent, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorTankParent?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { parent, error };
};

export function RerunCummulativeVariance(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/PostDipReadingVarianceReset";
  const requestBody = {
    dipMonth: data.DipMonth,
    dipYear: data.DipYear,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorTankByParent = (VendorLocationID, ParentID, refresh) => {
  const { data: tank, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorTankByParent?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&TankParentID=${ParentID}`,
    { refresh }
  );
  return { tank, error };
};

export function UpdateProduct(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateVendorProduct";
  const requestBody = {
    productName: data.ProductName,
    productImageID: data.ProductImageID,
    uom: data.UOM,
    buyingTax: data.TaxRate,
    sellingTax: data.TaxRate,
    IsStockItem: data.IsStockItem ? 1 : 0,
    productCategoryID: data.ProductCategoryID,
    productCategoryBrandID: data.productCategoryBrandID,
    productVolumeSizeID: data.productVolumeSizeID,
    productCategoryTypeID: data.ProductCategoryTypeID,
    addedBy: UserID,
    productID: data.ProductID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DeleteVendorProduct(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/RemoveVendorProduct";
  const requestBody = {
    AddedBy: UserID,
    ProductID: data.ProductID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };
  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorProductCategory = (VendorLocationID, refresh) => {
  const { data: category, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorProductCategory?VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { category, error };
};

export const GetVendorAllProducts = (refresh) => {
  const { data: allProduct, error } = useApiRes(
    Main_URL + `Stock/GetVendorProduct?VendorID=${VendorID}`,
    { refresh }
  );
  return { allProduct, error };
};

export const SearchVendorProduct = (searchText, refresh) => {
  const { data: suggestion, error } = useApiRes(
    Main_URL + `Stock/SearchVendorProduct?SearchTerm=${searchText}`,
    { refresh }
  );
  return { suggestion, error };
};

export const GetVendorProfile = (refresh) => {
  const { data: profile, error } = useApiRes(
    Main_URL + `Admin/GetVendorProfile?VendorID=${VendorID}`,
    { refresh }
  );
  return { profile, error };
};

export const GetVendorInvoice = (refresh) => {
  const { data: invoice, error } = useApiRes(
    Main_URL + `Account/GetVendorAccountInvoice?VendorID=${VendorID}`,
    { refresh }
  );
  return { invoice, error };
};

export function AddVendorAccountPayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Account/RequestSTKPush";
  const requestBody = {
    phoneNumber: data.PhoneNumber,
    invoiceNO: data.InvoiceNO,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    result: asyncSubmit.result,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ConfirmAccountPayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Account/CompleteServicePayment";
  const requestBody = {
    transID: data.TransID,
    invoiceNO: data.InvoiceNO,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function CheckSTKPushAccountPayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL =
    Main_URL + `Account/CheckStkPush?CheckoutRequestID=${data.TransID}`;
  const requestBody = {
    phoneNumber: data.PhoneNumber,
    invoiceNO: data.InvoiceNO,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    result: asyncSubmit.result,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function CompleteAccountPayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Account/RequestSTKPush";
  const requestBody = {
    phoneNumber: data.PhoneNumber,
    invoiceNO: data.InvoiceNO,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetCashClockSales = (ClockNO, refresh) => {
  const { data: sales, error } = useApiRes(
    Main_URL + `Sales/GetClockAllSales?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { sales, error };
};

export const GetShiftDailySales = (ClockDate, VendorLocationID, refresh) => {
  const { data: sales, error } = useApiRes(
    Main_URL +
      `Sales/GetShiftDailyDetailSales?ClockDate=${ClockDate}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { sales, error };
};

export const GetShiftDipReading = (ShiftRefNO, refresh) => {
  const { data: dips, error } = useApiRes(
    Main_URL + `Stock/GetShiftDipReading?ShiftRefNO=${ShiftRefNO}`,
    { refresh }
  );
  return { dips, error };
};

export function AddSalePayment(data) {
  return fetch(Main_URL + "Finance/UsePaymentCollection", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorPaymentCollectionID: data.VendorPaymentCollectionID,
      VendorPaymentMethodID: data.VendorPaymentMethodID,
      SaleID: data.SaleID,
      TransID: data.TransID,
      TransAmount: data.TransAmount,
      AddedBy: UserID,
    }),
  });
}

export const GetCustomerPrePayment = (refresh) => {
  const { data: prepayment, error } = useApiRes(
    Main_URL + `Finance/GetVendorPrepayAccount?VendorID=${VendorID}`,
    { refresh }
  );
  return { prepayment, error };
};

export function AddSalesTX(data) {
  return fetch(Main_URL + "Finance/UsePaymentCollection", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      UserID: UserID,
      ClockNO: "string",
      VendorID: 0,
      VendorLocationID: 0,
      CustomerID: 0,
      AssetID: 0,
      TotalAmount: 0,
      SaleID: "string",
      SaleDetailArray: [
        {
          ShiftNO: "string",
          ProductID: 0,
          PumpID: 0,
          VendorTankParentNozzleID: 0,
          ProductPrice: 0,
          Quantity: 0,
          TotalAmount: 0,
        },
      ],
      SalePaymentArray: [],
    }),
  });
}

export const GetPrimaryTopupType = (VendorLocationID, refresh) => {
  const { data: types, error } = useApiRes(
    Main_URL + `Finance/GetPrimaryTopUpType`,
    { refresh }
  );
  return { types, error };
};

export const GetDailyShiftNonshift = (ClockDate, VendorLocationID, refresh) => {
  const { data: nonshift, error } = useApiRes(
    Main_URL +
      `Finance/GetShiftDailyDetailNonShift?ClockDate=${ClockDate}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { nonshift, error };
};

export function AddVendorTopUpType(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorTopupType";
  const requestBody = {
    vendorTopUpTypeName: data.VendorTopUpTypeName,
    topUpTypeID: data.TopUpTypeID,
    addedBy: UserID,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorTopUpTypes = (VendorLocationID, refresh) => {
  const { data: types, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorTopUpType?VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { types, error };
};

export function AddCustomerTopUp(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/SaveClockTopUp";
  const requestBody = {
    vendorID: VendorID,
    vendorPaymentMethodID: data.VendorPaymentMethodID,
    vendorLocationID: data.VendorLocationID,
    clockNO: data.ClockNO,
    ShiftClockNO: data.ShiftClockNO,
    amount: data.Amount,
    UserID: data.UserID,
    addedBy: UserID,
    topUpTypeID: data.TopUpTypeID,
    vendorTopUpTypeID: data.VendorTopUpTypeID,
    customerID: data.CustomerID,
    refNO: data.ReferenceNO,
    comment: data.Comment,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddCustomerPrepayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorTopUpPrepay";
  const requestBody = {
    vendorCustomerID: data?.VendorCustomerID,
    vendorPaymentMethodID: data?.VendorPaymentMethodID,
    referenceNO: data?.ReferenceNO,
    topUpAmount: data?.Amount,
    addedBy: UserID,
    vendorID: VendorID,
    transactionDate: data?.TransactionDate,
    vendorLocationID: data?.VendorLocationID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReverseCustomerTopUp(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ReverseClockTopUp";
  const requestBody = {
    vendorTopUpID: data.VendorTopUpID,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AdminReverseCustomerTopUp(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ReverseVendorTopUpPrepay";
  const requestBody = {
    vendorCustomerTopUpID: data.VendorCustomerTopUpID,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetStockLevel = (VendorLocationID, refresh) => {
  const { data: stocks, loading: isLoading } = useApiRes(
    Main_URL +
      `Stock/GetVendorStockLevel?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { stocks, isLoading };
};

export const GetVendorLocationStockLevel = (values, refresh) => {
  const { DateRange, VendorLocationID, ProductCategoryID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: stocks, loading: isLoading } = useApiRes(
    Main_URL +
      `StockReport/GetProductStockLevel?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}&ProductCategoryID=${ProductCategoryID}`,
    { refresh }
  );
  return { stocks, isLoading };
};

export function UpdateStockLevel(data) {
  return fetch(Main_URL + "Stock/UpdateStockLevel", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      StockLevelID: data.StockLevelID,
      Quantity: data.Quantity,
      vendorStorageLocationID: data.VendorStorageLocationID,
      PreviousQuantity: data.PreviousQty,
      ProductID: data.ProductID,
      VendorID: VendorID,
      VendorLocationID: data.VendorLocationID,
      AddedBy: UserID,
    }),
  });
}

export const GetExpense = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: expense, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorExpense?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { expense, error };
};

export function AddVendorExpense(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddVendorExpense";
  const requestBody = {
    ClockNO: data.ClockNO ? data.ClockNO : "",
    shiftClockNo: data.ShiftClockNo,
    VendorPaymentMethodID: data.VendorPaymentMethodID,
    UserID: UserID,
    customerID: data.CustomerID,
    VendorID: VendorID,
    VendorLocationID: data.VendorLocationID,
    Description: data.Description,
    ExpenseAmount: data.ExpenseAmount,
    VendorExpenseTypeID: data.VendorExpenseTypeID,
    AddedBy: UserID,
    transactionDate: data.TransactionDate,
    type: data.ClockNO ? 0 : 1,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateVendorExpense(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateVendorExpense";
  const requestBody = {
    vendorExpenseID: data.VendorExpenseID,
    description: data.Description,
    transactionDate: data.TransactionDate,
    actionedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReverseVendorSale(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Sales/ReverseSaleTx";
  const requestBody = {
    UserID: UserID,
    SaleID: data.SaleID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateDipReading(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateDipReading";
  const requestBody = {
    DipID: data.DipID,
    NewReading: data.NewReading,
    AddedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetJournalEntry = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: journal, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorJournalEntry?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { journal, error };
};

export const GetAccounts = (refresh) => {
  const { data: accounts, error } = useApiRes(
    Main_URL + `Finance/GetAccounts`,
    { refresh }
  );
  return { accounts, error };
};

export function UpdateNozzleReading(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/UpdateTankPumpNozzleReadings";
  const requestBody = {
    shiftID: data.ShiftID,
    clockNO: data.ClockNO,
    eOpeningReading: data.EOpeningReading,
    mOpeningReading: data.MOpeningReading,
    cOpeningReading: data.COpeningReading,
    eClosingReading: data.EClosingReading,
    mClosingReading: data.MClosingReading,
    cClosingReading: data.CClosingReading,
    VendorLocationID: data.VendorLocationID,
    changeType: data.changeType,
    closedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetPaymentMethod = (refresh) => {
  const { data: pmethods, error } = useApiRes(
    Main_URL + `Finance/GetPaymentMethod`,
    { refresh }
  );
  return { pmethods, error };
};

export const GetLotSUmmary = (
  VendorLocationID,
  ShiftNO,
  clockType,
  refresh
) => {
  const { data: lots, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetLotRecon?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&LotDate=${ShiftNO}&ClockTypeID=${clockType}`,
    { refresh }
  );
  return { lots, error };
};

export const GetShiftSUmmary = (
  VendorLocationID,
  ShiftNO,
  ShiftDate,
  refresh
) => {
  const { data: summary, error } = useApiRes(
    Main_URL +
      `SalesReport/GetShiftSummaryReport?ShiftDate=${ShiftDate}&ShiftTypeID=1&VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&LotNO=${ShiftNO}`,
    { refresh }
  );
  return { summary, error };
};

export const GetShiftDetailedReport = (
  VendorLocationID,
  ShiftClockNo,
  refresh
) => {
  const { data: report, error } = useApiRes(
    Main_URL +
      `SalesReport/GetShiftSummaryResult?VendorLocationID=${VendorLocationID}&ShiftClockNO=${ShiftClockNo}`,
    { refresh }
  );
  return { report, error };
};

export const GetLotCollection = (
  VendorLocationID,
  ShiftNO,
  clockType,
  refresh
) => {
  const { data: collection, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetLotCollection?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}&LotDate=${ShiftNO}&ClockTypeID=${clockType}`,
    { refresh }
  );
  return { collection, error };
};

export function AddLotCollection(data, VendorLocationID, ShiftNO) {
  return fetch(Main_URL + "Finance/PostVendorLotCollectionTx", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      UserID: UserID,
      LotNO: moment(ShiftNO).format("YYYYMMDD"),
      VendorID: VendorID,
      VendorLocationID: VendorLocationID,
      LotCollectionArray: [data],
    }),
  });
}

export function ReverseMarkedPayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ReverseMarkedPayment";
  const requestBody = {
    ID: data.ID,
    UserClockPaymentTransActionID: data.UserClockPaymentTransActionID,
    ReversedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ApproveExpense(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ApproveVendorExpense";
  const requestBody = {
    VendorExpenseID: data.VendorExpenseID,
    ApprovedAmount: data.ApprovedAmount,
    ActionedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RejectExpense(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/RejectVendorExpense";
  const requestBody = {
    VendorExpenseID: data.VendorExpenseID,
    comment: "",
    ActionedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DeactivatePayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/DeactivateTransAction";
  const requestBody = {
    ID: data.ID,
    UserID: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReactivatePayment(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ReActivateTransAction";
  const requestBody = {
    ID: data.ID,
    UserID: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReopenNozzle(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Stock/ReopenTankPumpNozzleReading";
  const requestBody = {
    ShiftNO: data.ShiftNO,
    ClockNO: data.ClockNO,
    AddedBy: UserID,
    ShiftID: data.ShiftID,
    VendorLocationID: data.VendorLocationID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReverseCollection(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ReverseCollection";
  const requestBody = {
    VendorPaymentCollectionID: data.VendorPaymentCollectionID,
    CollectionNO: data.CollectionNO,
    ClockNO: data.ClockNO,
    ReversedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function BillClockAttendant(data) {
  return fetch(Main_URL + "Finance/PostUserClockBilling", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      ClockNO: data.ClockNO,
      UserID: data.UserID,
      BillingAmount: data.Variance,
      Comment: data.Comment,
      AddedBy: UserID,
    }),
  });
}

export function UserAccountPayment(data) {
  return fetch(Main_URL + "Finance/PostUserClockBillPayment", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorPaymentMethodID: data.VendorPaymentMethodID,
      PaidAmount: data.PaidAmount,
      UserClockBillingAccountID: data.UserClockBillingAccountID,
      Comment: data.Comment,
      AddedBy: UserID,
    }),
  });
}

export const GetPumpAssignment = (VendorLocationID, refresh) => {
  const { data: assignment, error } = useApiRes(
    Main_URL +
      `Supervisor/GetUserPumpAssignment?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}`,
    { refresh }
  );
  return { assignment, error };
};

export const GetUserDeductions = (VendorLocationID, refresh) => {
  const { data: variace, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorUserClockAccount?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { variace, error };
};

export const GetClockExpense = (ClockNO, refresh) => {
  const { data: expense, error } = useApiRes(
    Main_URL + `Finance/GetVendorClockExpense?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { expense, error };
};

export const GetDailyShiftExpense = (ClockDate, VendorLocationID, refresh) => {
  const { data: expense, error } = useApiRes(
    Main_URL +
      `Finance/GetShiftDailyDetailExpense?ClockDate=${ClockDate}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { expense, error };
};

export const GetClockSales = (ClockNO, refresh) => {
  const { data: sales, error } = useApiRes(
    Main_URL + `Sales/GetClockAllSales?ClockNO=${ClockNO}`,
    { refresh }
  );
  return { sales, error };
};

export const GetCustomerSales = (CustomerID, DateRange, refresh) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: sales, error } = useApiRes(
    Main_URL +
      `Sales/GetCustomerSaleHistory?CustomerID=${CustomerID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { sales, error };
};

export function PostClockSale(data, successFn, errorFn) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Sales/PostVendorSales";
  const requestBody = {
    userID: UserID,
    clockNO: data.ClockNO,
    AssetMileage: data.AssetMileage,
    DateAdded: `${passedDate} ${currentTime}`,
    isStation: 0,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    customerID: data.CustomerID,
    customerTypeID: data.CustomerTypeID,
    assetID: data.AssetID,
    totalAmount: data.TotalAmount,
    MoreInfo: data.MoreInfo || "",
    saleID: "0",
    saleDetailArray: data.selected,
    salePaymentArray: data.receipt,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateClockSale(data, successFn, errorFn) {
  console.log("érd", data);
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Supervisor/UpdateSaleData";
  const requestBody = {
    saleOrder: {
      id: data.ID,
      receivedAmount: data.ReceivedAmount,
      TotalAmount: data?.SaleDetailArray?.filter(
        (p) => p.StatusID === 1
      )?.reduce((a, b) => {
        return a + b.TotalAmount;
      }, 0),
      dateAdded: `${passedDate} ${currentTime}`,
      moreInfo: data.MoreInfo,
    },
    saleOrderInvoice: {
      saleOrderInvoiceID: 0,
      saleID: data.SaleID,
      customerID: data.CustomerID,
      assetID: data.AssetID,
    },
    saleDetails: data?.SaleDetailArray,
    addedBy: UserID,
    ClockNO: data.ClockNO,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetCustomerUsers = (CustomerID, refresh) => {
  const { data: users, error } = useApiRes(
    Main_URL + `Customer/GetVendorCustomerUsers?CustomerID=${CustomerID}`,
    { refresh }
  );
  return { users, error };
};

export const GetShiftRefs = (VendorLocationID, date, refresh) => {
  const newDate = moment(date).format("YYYY-MM-DD");
  const { data: ShiftInfoList, error } = useApiRes(
    Main_URL +
      `Supervisor/GetShiftRefNosByDateAndVendorLocation?DateClockedIN=${newDate}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { ShiftInfoList, error };
};

export const GetCustomerPayments = (CustomerID, DateRange, refresh) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: payments, error } = useApiRes(
    Main_URL +
      `Finance/GetCustomerPayment?CustomerID=${CustomerID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { payments, error };
};

export const GetCustomerStations = (CustomerID, refresh) => {
  const { data: stations, error } = useApiRes(
    Main_URL + `Customer/GetVendorCustomerLocation?CustomerID=${CustomerID}`,
    { refresh }
  );
  return { stations, error };
};

export function AddCustomerLocation(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/AddCustomerFuelingLocation";
  const requestBody = {
    vendorLocationID: data.VendorLocationID,
    vendorID: VendorID,
    vendorCustomerID: data.VendorCustomerID,
    contactEmail: "",
    vendorLocationPhone: "",
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DeleteCustomerLocation(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Customer/RemoveVendorCustomer";
  const requestBody = {
    customerID: data.VendorLocationID,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetCustomerDiscount = (CustomerID, refresh) => {
  const { data: discount, error } = useApiRes(
    Main_URL + `Finance/GetVendorCustomerDiscount?CustomerID=${CustomerID}`,
    { refresh }
  );
  return { discount, error };
};

export function PostCustomerDiscount(data) {
  console.log(data);
  return fetch(Main_URL + "Finance/AddVendorCustomerDiscount", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      productID: data.ProductID,
      discountAmount: data.Amount,
      addedBy: UserID,
      customerID: data.VendorCustomerID,
    }),
  });
}

export function DeactivateCustomerDiscount(data) {
  return fetch(Main_URL + "Finance/DisableCustomerDiscount", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      customerDiscountID: data.CustomerDiscountID,
      addedBy: UserID,
    }),
  });
}

export const GetCustomerStatement = (CustomerID, DateRange, refresh) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: statement, loading } = useApiRes(
    Main_URL +
      `Sales/GetnewCustomerStatement?CustomerID=${CustomerID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { statement, loading };
};

export const GetCustomerAssets = (CustomerID, DateRange, refresh) => {
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: assets, error } = useApiRes(
    Main_URL +
      `Sales/GetCustomerAsset?CustomerID=${CustomerID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { assets, error };
};

export const GetExpenseType = (VendorLocationID, refresh) => {
  const { data: types, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorExpenseType?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { types, error };
};

export function AddExpenseType(data) {
  return fetch(Main_URL + "Finance/AddVendorExpenseType", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorID: VendorID,
      VendorLocationID: data.VendorLocationID,
      VendorExpenseTypeName: data.ExpenseType,
      AddedBy: UserID,
    }),
  });
}

export function UpdateExpenseType(data) {
  return fetch(Main_URL + "Finance/UpdateVendorExpenseType", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorID: VendorID,
      VendorLocationID: data.VendorLocationID,
      VendorExpenseTypeName: data.VendorExpenseTypeName,
      AddedBy: UserID,
    }),
  });
}

export function RemoveExpenseType(data) {
  return fetch(Main_URL + "Finance/UpdateVendorExpenseType", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      VendorExpenseTypeID: data.VendorExpenseTypeID,
      AddedBy: UserID,
    }),
  });
}

export const GetProductSales = (values, refresh) => {
  const { DateRange, VendorLocationID, ProductID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: sales, error } = useApiRes(
    Main_URL +
      `StockReport/GetStockProductSalesReport?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&ProductID=${ProductID}`,
    { refresh }
  );
  return { sales, error };
};

export const GetProductStatment = (values, refresh) => {
  const { DateRange, VendorLocationID, ProductID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: statement, error } = useApiRes(
    Main_URL +
      `StockReport/GetVendorProductStatement?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}&ProductID=${ProductID}`,
    { refresh }
  );
  return { statement, error };
};

export const GetAllProductPurchase = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: purchase, error } = useApiRes(
    Main_URL +
      `StockReport/GetProductPurchaseReport?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { purchase, error };
};

export const GetProductPurchase = (values, refresh) => {
  const { DateRange, VendorLocationID, ProductID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: purchase, error } = useApiRes(
    Main_URL +
      `StockReport/GetStockProductPurchaseReport?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&ProductID=${ProductID}`,
    { refresh }
  );
  return { purchase, error };
};

export const GetBalanceSheet = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: balance, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetBalanceSheet?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { balance, error };
};

export const GetTrailBalance = (VendorLocationID, year, month, refresh) => {
  const { data: balance, loading } = useApiRes(
    Main_URL +
      `FinanceReport/GetTrialBalance?VendorLocationID=${VendorLocationID}&Month=${month}&Year=${year}`,
    { refresh }
  );
  return { balance, loading };
};

export const GetCashIn = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: cashIn, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetVendorCashIN?VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { cashIn, error };
};

export const GetCashFlow = (VendorLocationID, Year, refresh) => {
  const { data: cashflow, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetCashflowStatement?VendorLocationID=${VendorLocationID}&Year=${Year}`,
    { refresh }
  );
  return { cashflow, error };
};

export const GetVendorLocationAttendanceVariance = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: variance, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorLocationAttendnantVariance?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { variance, error };
};

export const GetVendorAttendanceStatement = (values, refresh) => {
  const { DateRange, UserID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: statement, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorAttendantVarianceStatement?StartDate=${start}&EndDate=${end}&UserID=${UserID}`,
    { refresh }
  );
  return { statement, error };
};

export const GetVendorAttendantVariance = (values, refresh) => {
  const { DateRange, UserID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: variace, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorAttendantVariance?StartDate=${start}&EndDate=${end}&UserID=${UserID}`,
    { refresh }
  );
  return { variace, error };
};

export const GetVendorAttendantVariancePayment = (values, refresh) => {
  const { DateRange, UserID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: payment, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorAttendantVariancePayment?StartDate=${start}&EndDate=${end}&UserID=${UserID}`,
    { refresh }
  );
  return { payment, error };
};

export function AddAttendantVariancePayment(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddAttendnatVariancePayment";
  const requestBody = {
    vendorAttendantVariancePaymentID: 0,
    vendorPaymentMethodID: data.VendorPaymentMethodID,
    userID: data.UserID,
    dateAdded: data.TransDate,
    amountPaid: data.Amount,
    vendorTopUpID: 0,
    clockNO: data.ClockNO,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function WriteOffAttendantVariance(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/WriteOffAttendnantVariance";
  const requestBody = {
    variences: data.variances,
    addedBy: UserID,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetCashOut = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: cashOut, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetVendorCashOut?VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { cashOut, error };
};

export function AddMpesaPayment(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddPaymentMessage";
  const requestBody = {
    VendorID: VendorID,
    VendorLocationID: data.VendorLocationID,
    VendorPaymentMethodID: data.VendorPaymentMethodID,
    TransID: data.TransID,
    ClockNO: data.ClockNO || "",
    TransTime: moment(data.Transtime).format("hh:mm A"),
    TransDate: moment(data.Transtime).format("DD/MM/YY"),
    MpesaAmount: accountingFormat(+data.MpesaAmount),
    TransactionCost: accountingFormat(+data.TransactionCost || 0),
    AccountBalance: accountingFormat(+data.AccountBalance || 0),
    AddedBy: UserID,
    UserID: 0,
    CustomerName: data.CustomerName?.trim(),
    CustomerPhone: "254" + data.CustomerPhone,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddMpesaBulkPayment(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/AddPaymentMessages";
  const requestBody = {
    VendorID: VendorID,
    VendorLocationID: data.VendorLocationID,
    VendorPaymentMethodID: data.VendorPaymentMethodID,
    TransID: data.TransID,
    ClockNO: data.ClockNO || "",
    TransTime: moment(data.Transtime).format("hh:mm A"),
    TransDate: moment(data.Transtime).format("DD/MM/YY"),
    MpesaAmount: accountingFormat(+data.MpesaAmount),
    TransactionCost: accountingFormat(+data.TransactionCost || 0),
    AccountBalance: accountingFormat(+data.AccountBalance || 0),
    AddedBy: UserID,
    UserID: 0,
    CustomerName: data.CustomerName?.trim(),
    CustomerPhone: "254" + data.CustomerPhone,
    isClosingBalance: 0,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetIncomestatement = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: income, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetVendorIncomeStatement?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { income, error };
};

export const GetTransactionMessages = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: messages, error } = useApiRes(
    Main_URL +
      `Finance/GetPaymentTransActionMessage?VendorLocationID=${VendorLocationID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    { refresh }
  );
  return { messages, error };
};

export const GetPaymentMethodTransactions = (values, refresh) => {
  const { DateRange, VendorPaymentMethodID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: trans, error } = useApiRes(
    Main_URL +
      `Finance/GetVendorPaymentMethodTrasaction?StartDate=${start}&EndDate=${end}&VendorPaymentMethodID=${VendorPaymentMethodID}`,
    { refresh }
  );
  return { trans, error };
};

export function AddVendor(data) {
  return fetch(Main_URL + "Admin/PostVendorRegistrationTx", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      userID: 0,
      vendorID: 0,
      vendorClientID: 0,
      vendorLocationID: 0,
      addedBy: 0,
      vendorName: data.VendorName,
      firstName: data.FirstName,
      lastName: data.LastName,
      phoneNumber: data.PhoneNumber,
      email: data.Email,
      slogan: "",
      vendorImageID: 0,
      stationsArray: data.StationsArray,
    }),
  });
}

export function MarkMpesaPayment(data, successFn, errorFn) {
  const noerrorcheck = true;
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/MarkTransActionMultiple";
  const requestBody = {
    UserID: UserID,
    ClockNO: data.ClockNO,
    MarkedPaymentArray: data.MarkedPaymentArray,
  };

  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody, noerrorcheck);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetMonthlyReport = (refresh) => {
  const { data: report, error } = useApiRes(
    Main_URL + `SalesReport/GetMonthlyReport?VendorID=${VendorID}`,
    { refresh }
  );
  return { report, error };
};

export const GetVendorPayroll = (refresh) => {
  const { data: payroll, error } = useApiRes(
    Main_URL + `Payroll/GetVendorPayroll?VendorID=${VendorID}`,
    { refresh }
  );
  return { payroll, error };
};

export function AddVendorPayroll(data) {
  return fetch(Main_URL + "Payroll/AddVendorPayroll", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorID: VendorID,
      vendorLocationID: data.VendorLocationID,
      startDate: moment(data.StartDate).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      payrollNO: "",
      dateAdded: new Date(),
      addedBy: UserID,
      statusID: 1,
      payrollDetailArray: data.rows,
    }),
  });
}

export const GetVendorPayrollSalary = (VendorLocationID, refresh) => {
  const { data: salary, error } = useApiRes(
    Main_URL +
      `Payroll/GetVendorPayrollSalary?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { salary, error };
};

export function AddPayrollSalary(data) {
  return fetch(Main_URL + "Payroll/AddVendorPayrollSalary", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorPayrollSalaryID: 0,
      userID: data.UserID,
      vendorID: VendorID,
      vendorLocationID: data.VendorLocationID,
      paymentType: "monthly",
      salary: data.Salary,
      statusID: 1,
      dateAdded: new Date(),
      addedBy: UserID,
      taxRate: data.TaxRate,
    }),
  });
}

export const GetVendorPayrollDeductions = (PayrollDetailID, refresh) => {
  const { data: deductions, error } = useApiRes(
    Main_URL + `Payroll/yrollDeduction?PayrollDetailID=${PayrollDetailID}`,
    { refresh }
  );
  return { deductions, error };
};

export function AddPayrollDeduction(data) {
  return fetch(Main_URL + "Payroll/AddVendorPayrollDeduction", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorPayrollDeductionID: 0,
      payrollDetailID: data.PayrollDetailID,
      deductionItemID: data.VendorPayrollDeductionItemID,
      amount: data.Amount,
      userID: data.UserID,
      dateAdded: new Date(),
      description: data.Description,
      addedBy: UserID,
      statusID: 1,
    }),
  });
}

export function UpdateUserClockVariance(UserClockVarianceID) {
  return fetch(
    Main_URL +
      `Payroll/UpdateUserClockVariance?UserClockVarianceID=${UserClockVarianceID}`,
    {
      headers: new Headers({
        Funguo: Funguo,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      method: "post",
      // body: JSON.stringify({
      //   vendorPayrollDeductionID: 0,
      //   payrollDetailID: data.PayrollDetailID,
      //   deductionItemID: data.VendorPayrollDeductionItemID,
      //   amount: data.Amount,
      //   userID: data.UserID,
      //   dateAdded: new Date(),
      //   description: data.Description,
      //   addedBy: UserID,
      //   statusID: 1,
      // }),
    }
  );
}

export const GetVendorPayrollDeductionItems = (refresh) => {
  const { data: deductitems, error } = useApiRes(
    Main_URL + `Payroll/GetVendorDeductionItem?VendorID=${VendorID}`,
    { refresh }
  );
  return { deductitems, error };
};

export function AddVendorDeductionItem(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Payroll/AddVendorDeductionItem";
  const requestBody = {
    vendorPayrollDeductionItemID: 0,
    deductionItem: data.DeductionItem,
    vendorID: VendorID,
    dateAdded: new Date(),
    addedBy: UserID,
    statusID: 1,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddVendorBenefitItem(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Payroll/AddVendorBenefitItem";
  const requestBody = {
    benefitItem: data.BenefitItem,
    vendorID: VendorID,
    dateAdded: new Date(),
    addedBy: UserID,
    statusID: 1,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetVendorPayrollBenefit = (PayrollDetailID, refresh) => {
  const { data: benefits, error } = useApiRes(
    Main_URL +
      `Payroll/GetVendorPayrollBenefit?PayrollDetailID=${PayrollDetailID}`,
    { refresh }
  );
  return { benefits, error };
};

export function AddPayrollBenefit(data) {
  return fetch(Main_URL + "Payroll/AddVendorPayrollBenefit", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      vendorPayrollBenefit: 0,
      payrollDetailID: data.PayrollDetailID,
      benefitItemID: data.VendorPayrollBenefitItemID,
      amount: data.Amount,
      userID: data.UserID,
      dateAdded: new Date(),
      description: data.Description,
      addedBy: UserID,
      statusID: 1,
    }),
  });
}

export const GetVendorPayrollBenefitItem = (refresh) => {
  const { data: benefititem, error } = useApiRes(
    Main_URL + `Payroll/GetVendorBenefitItem?VendorID=${VendorID}`,
    { refresh }
  );
  return { benefititem, error };
};

export const GetVendorUserClockVariance = (values, refresh) => {
  const { DateRange, UserID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: variance, error } = useApiRes(
    Main_URL +
      `Payroll/GetUserClockVariance?StartDate=${start}&EndDate=${end}&UserID=${UserID}`,
    { refresh }
  );
  return { variance, error };
};

export const GetVendorOpenTankNozzle = (VendorLocationID, refresh) => {
  const { data: opennozzle, error } = useApiRes(
    Main_URL +
      `Stock/GetVendorOpenTankNozzle?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    { refresh }
  );
  return { opennozzle, error };
};

export function sendHelpRequest(request) {
  return fetch(`https://graph.facebook.com/v15.0/111373551751141/messages`, {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " +
        "EAASyPtQ8v4gBAPmgXV16ySDF8ZA19tHLbZCwD8Epg4wYXEYxEKLD11XjJXZBv5FnBuEiz3qXIzIlkzHhItQ14ZAVeJJezmrDaA4rZC3OaAnS1FZCsdKZAmURXOCP1dIz1AV6Mz80cfbnRSZCsDO8lMjDW2XT4TlNmjcaP8tnHgJ7fPXfzRbfDqh3",
    }),
    method: "post",
    body: JSON.stringify({
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: request.PhoneNumber,
      type: "template",
      template: {
        name: "help_request",
        language: {
          code: "en_US",
        },
        components: [
          {
            type: "header",
            parameters: [
              {
                type: "image",
                image: {
                  link: "https://api.crato.co.ke/api/File/VendorImage?ImageID=help",
                },
              },
            ],
          },
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: request.FullName,
              },
              {
                type: "text",
                text: request.Message || "N/A",
              },
            ],
          },
        ],
      },
    }),
  });
}

export const GetAccountTransfer = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: transfers, error } = useApiRes(
    Main_URL +
      `Finance/GetAccountsTransfer?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    {
      refresh,
    }
  );
  return { transfers, error };
};

export const GetVendorShiftAccountRecon = (
  ShiftRefNO,
  ShiftClockNO,
  VendorLocationID,
  refresh
) => {
  const { data: recon, error } = useApiRes(
    Main_URL +
      `FinanceReport/GetPaymentMethodShiftBalances?ShiftRefNO=${ShiftRefNO}&ShiftClockNO=${ShiftClockNO}&VendorLocationID=${VendorLocationID}`,
    {
      refresh,
    }
  );
  return { recon, error };
};

export const GetAccountStatement = (values, refresh) => {
  const { DateRange, VendorPaymentMethodID, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: statement, loading } = useApiRes(
    Main_URL +
      `Finance/GetAccountsStatement?PaymentMethodID=${VendorPaymentMethodID}&VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    {
      refresh,
    }
  );
  return { statement, loading };
};

export function AddAccountTransfer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/PostAccountTransfer";
  const requestBody = {
    accountTransferID: 0,
    shiftClockNO: data.ShiftClockNO,
    creditAccountID: data.CreditAccountID,
    debitAccountID: data.DebitAccountID,
    transactionCost: data.Charges,
    amount: data.Amount,
    dateAdded: new Date(),
    vendorLocationID: data.VendorLocationID,
    vendorID: VendorID,
    transDate: data.TransDate,
    description: data.Description,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function UpdateAccountTransfer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/UpdateAccountTransfer";
  const requestBody = {
    accountTransferID: data.AccountTransferID,
    creditAccountID: data.CreditAccountID,
    shiftClockNO: data.ShiftClockNO,
    debitAccountID: data.DebitAccountID,
    amount: data.Amount,
    transactionCost: data.TransactionCost,
    dateAdded: new Date(),
    vendorLocationID: data.VendorLocationID,
    vendorID: VendorID,
    transDate: data.TransDate,
    description: data.Description,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function ReverseAccountTransfer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Finance/ReverseAccountTransfer";
  const requestBody = {
    accountTransferID: data.AccountTransferID,
    debitAccountID: data.DebitAccountID,
    VendorLocationID: data.VendorLocationID,
    amount: data.Amount,
    addedBy: UserID,
    transferDate: data.DateAdded,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetDistributorVendors = (values, refresh) => {
  const { data: vendors, error } = useApiRes(
    Main_URL + `Distributor/GetDistributorVendor?VendorID=${VendorID}`,
    {
      refresh,
    }
  );
  return { vendors, error };
};

export function AddDistributorDeliveryPoint(data) {
  return fetch(Main_URL + "Distributor/PostDistributorLpoDeliveryTx", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      purchaseNO: data.PurchaseNO,
      orderNO: data.OrderNO,
      eta: moment(new Date()).format("YYYY-MM-DD"),
      deliveryCost: data.DeliveryCost,
      vendorID: data.VendorID,
      vendorLocationID: data.VendorLocationID,
      deliveryDetail: data.DeliveryDetail,
      purchaseDeliveryType: 0,
      addedBy: UserID,
      purchaseDeliveryArray: data.PurchaseDeliveryArray,
    }),
  });
}

export const GetUserRoleAssignment = (refresh) => {
  const { data: urole, error } = useApiRes(
    Main_URL + `Admin/GetUserRolesWithDetails?VendorID=${VendorID}`,
    {
      refresh,
    }
  );
  return { urole, error };
};

export const GetUserTypePolicy = (refresh) => {
  const { data: policy, error } = useApiRes(
    Main_URL + `Admin/GetUserTypePolicy`,
    {
      refresh,
    }
  );
  return { policy, error };
};

export const GetUserTypePolicyAssignment = (UserTypeID, refresh) => {
  const { data: roles, error } = useApiRes(
    Main_URL + `Admin/GetUserTypePolicyAssignment?userTypeID=${UserTypeID}`,
    {
      refresh,
    }
  );
  return { roles, error };
};

export const GetUserPolicyAssingment = (UserID, refresh) => {
  const { data: urole, error } = useApiRes(
    Main_URL + `Admin/GetUserPolicyAssignment?UserID=${UserID}`,
    {
      refresh,
    }
  );
  return { urole, error };
};

export const GetUserTypeRole = (UserType, refresh) => {
  const { data: roles, error } = useApiRes(
    Main_URL +
      `Admin/GetUserTypeRoles?vendorID=${VendorID}&userTypeID=${UserType}`,
    {
      refresh,
    }
  );
  return { roles, error };
};

export const GetNotificationTypes = (refresh) => {
  const { data: types, error } = useApiRes(
    Main_URL + `Notifications/GetNotificationType`,
    {
      refresh,
    }
  );
  return { types, error };
};

export const GetNotificationRecipient = (VendorLocationID, refresh) => {
  const { data: recipient, error } = useApiRes(
    Main_URL +
      `Notifications/GetNotificationUsers?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    {
      refresh,
    }
  );
  return { recipient, error };
};

export function AddNotificationRecipient(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Notifications/AddNotificationUser";
  const requestBody = {
    userID: data.UserID,
    notificationTypeID: data.NotificationTypeID,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    addedBy: UserID,
    mediumChannelID: 2,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function RemoveNotificationRecipient(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Notifications/RemoveWhatsAppReportRecipient";
  const requestBody = {
    notificationUserID: data.NotificationUserID,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddUserTypeRole(data, onSuccess, onError) {
  const url = Main_URL + "Admin/AddUserRoles";
  isOnline
    ? saveToIndexedDB(data, "AddUserRoles", "UserRoleID")
    : AddToApi(url, data, "AddUserRoles", onSuccess, onError);
}

export const GetAgeingReport = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: report, loading } = useApiRes(
    Main_URL +
      `Customer/GetAgeingReport?VendorLocationID=${VendorLocationID}&StartDate=${start}&EndDate=${end}`,
    {
      refresh,
    }
  );
  return { report, loading };
};

export const GetConsolidatedReport = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: report, error } = useApiRes(
    Main_URL +
      `AdminReport/GetConsolidatedVendorReport?UserID=${UserID}&VendorID=${VendorID}&StartDate=${start}&EndDate=${end}`,
    {
      refresh,
    }
  );
  return { report, error };
};

export const GetAuditTrail = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: report, error } = useApiRes(
    Main_URL +
      `AuditTrail/GetAuditTrail?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    {
      refresh,
    }
  );
  return { report, error };
};

export const GetAuditLogAnalytics = (values, refresh) => {
  const { DateRange, VendorLocationID } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD HH:mm");
  const end = moment(DateRange[1]).format("YYYY-MM-DD HH:mm");
  const { data: analytics, error } = useApiRes(
    Main_URL +
      `AuditTrail/GetAuditTrailAnalysis?StartDate=${start}&EndDate=${end}&VendorLocationID=${VendorLocationID}`,
    {
      refresh,
    }
  );
  return { analytics, error };
};

export function sendAttendantClockSummary(request) {
  return fetch(`https://graph.facebook.com/v15.0/111373551751141/messages`, {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " +
        "EAASyPtQ8v4gBAPmgXV16ySDF8ZA19tHLbZCwD8Epg4wYXEYxEKLD11XjJXZBv5FnBuEiz3qXIzIlkzHhItQ14ZAVeJJezmrDaA4rZC3OaAnS1FZCsdKZAmURXOCP1dIz1AV6Mz80cfbnRSZCsDO8lMjDW2XT4TlNmjcaP8tnHgJ7fPXfzRbfDqh3",
    }),
    method: "post",
    body: JSON.stringify({
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: 254727337354,
      type: "template",
      template: {
        name: "help_request",
        language: {
          code: "en_US",
        },
        components: [
          {
            type: "header",
            parameters: [
              {
                type: "image",
                image: {
                  link: "https://api.crato.co.ke/api/File/VendorImage?ImageID=help",
                },
              },
            ],
          },
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: request.FullName,
              },
              {
                type: "text",
                text: request.Message || "N/A",
              },
            ],
          },
        ],
      },
    }),
  });
}

export function BackdateLocationData(data) {
  const currentTime = moment().format("HH:mm:ss");
  const passedDate = moment(data.DateAdded).format("YYYY-MM-DD");
  return fetch(Main_URL + "Supervisor/BackdateVendorLocationData", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      ClockTypeID: data.ClockTypeID,
      VendorLocationID: data.VendorLocationID,
      DateAdded: `${passedDate} ${currentTime}`,
      shiftClockNo: data.ShiftNO,
    }),
  });
}

export function AddGoogleSheetCredentials(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "GoogleSheets/AddGoogleSheetCredentials";
  const requestBody = {
    userGoogleSheetCredentialsID: 0,
    googleSheetCredentials: data.GoogleSheetID,
    additionalInfo: "",
    vendorLocationID: data.VendorLocationID,
    dateAdded: new Date(),
    statusID: 1,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function BackupGoogleSheet(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "GoogleSheets/PostToGoogleSheet";
  const requestBody = {
    vendorLocationID: data.VendorLocationID,
    sheetName: "",
    googleSheetID: data.GoogleSheetID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddDistributorPurchase(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Distributor/PostDistributorPurchaseOrderTx";
  const requestBody = {
    vendorID: VendorID,
    purchaseNO: "",
    supplierID: data?.VendorSupplierID,
    deliveryAssetID: data?.AssetID,
    statusID: 2,
    addedBy: UserID,
    totalAmount:
      data?.stocks?.reduce((a, b) => a + b.LineTotal, 0) + +data?.OtherCost,
    deliveryCost: data?.OtherCost,
    invoiceNO: data?.InvoiceNO,
    purchaseDetailArray: data?.stocks,
    purchasePaymentArray: [],
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetDistributorDashbord = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: dash, loading } = useApiRes(
    Main_URL +
      `Distributor/GetDistributorDashBoard?StartDate=${start}&EndDate=${end}&VendorID=${VendorID}`,
    { refresh }
  );

  return { dash: dash?.Data || [], loading };
};

export const GetDistributorOrders = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data: order, loading } = useApiRes(
    Main_URL +
      `Distributor/GetDistributorSaleOrderHistory?VendorID=${VendorID}&StartDate=${start}&EndDate=${end}&PageNO=1&PageSize=1000`,
    { refresh }
  );

  return { order: order?.Data || [], loading };
};

export const GetDistributorAssets = (VendorLocationID, refresh) => {
  const { data: assets, error } = useApiRes(
    Main_URL +
      `Supervisor/GetVendorAssetAssignment?VendorID=${VendorID}&VendorLocationID=${VendorLocationID}`,
    {
      refresh,
    }
  );
  return { assets, error };
};

export const GetDistributorCustomers = (VendorLocationID, refresh) => {
  const { data: customer, error } = useApiRes(
    Main_URL + `Distributor/GetBulkCustomers?VendorID=${VendorID}`,
    {
      refresh,
    }
  );
  return { customer: customer.Data, error };
};

export const GetDistributorSupplier = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data, error } = useApiRes(
    Main_URL +
      `Distributor/GetDistributorSupplier?VendorID=${VendorID}&StartDate=${start}&EndDate=${end}&PageNO=1&PageSize=10`,
    {
      refresh,
    }
  );
  return { supplier: data?.Data, error };
};

export const GetDistributorPurchase = (values, refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data, error } = useApiRes(
    Main_URL +
      `Distributor/GetDistributorPurchase?VendorID=${VendorID}&StartDate=${start}&EndDate=${end}&PageNO=1&PageSize=10`,
    {
      refresh,
    }
  );
  return { purchase: data.Data, error };
};

export const GetDistributorAllAssets = (VendorLocationID, refresh) => {
  const { data, error } = useApiRes(
    Main_URL +
      `Customer/GetDistributorVendorAllClientAsset?VendorID=${VendorID}`,
    {
      refresh,
    }
  );
  return { asset: data, error };
};

export function AddDistributorFulfillDelivery(data) {
  return fetch(Main_URL + "Distributor/FulfillDelivery", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      id: data.DistributorPurchaseDeliveryID,
      addedBy: UserID,
    }),
  });
}

export function AddDistributorPurchasePayment(data) {
  return fetch(Main_URL + "Distributor/SaveLpoPayment", {
    headers: new Headers({
      Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      purchaseNO: data.PurchaseNO,
      amountPaid: data.AmountPaid,
      transActionCost: data.TransactionCost,
      vendorPaymentMethodID: data.VendorPaymentMethodID,
      referenceNO: data.ReferenceNO,
      addedBy: UserID,
    }),
  });
}

export function AddDistributorOrder(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Distributor/PostDistributorSaleOrderTx";
  const requestBody = {
    userID: UserID,
    vendorID: VendorID,
    purchaseNO: data.PurchaseNO,
    customerID: data.CustomerID,
    customerTypeID: data.CustomerTypeID,
    assetID: data.AssetID,
    saleType: 4,
    isStation: data.IsStation,
    stationID: data.StationID,
    deliveryAssetID: data.DeliveryAssetID,
    eta: data.ETA,
    dateDelivered: "",
    deliveredByID: 0,
    statusID: 0,
    handlingCost: data.HandlingCost,
    deliveryCost: data.DeliveryCost,
    deliveryDetail: "",
    totalAmount: data.TotalAmount,
    saleDetailArray: data.stocks,
    salePaymentArray: [],
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DistributorCompleteDelivery(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Driver/CompleteDelivery";
  const requestBody = {
    id: data.VendorPurchaseID,
    addedBy: UserID,
    vendorAssetID: data.DeliveryAssetID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DistributorLoadTruck(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Driver/CompleteLoading";
  const requestBody = {
    id: data.VendorPurchaseID,
    addedBy: UserID,
    vendorAssetID: data.DeliveryAssetID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function DistributorVehicle(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Distributor/AddDeliveryAsset";
  const requestBody = {
    vendorAssetID: 0,
    vendorID: VendorID,
    vendorLocationID: data.VendorLocationID,
    vendorAssetName: data.VendorAssetName,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AssignDistributorVehicle(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Distributor/AddDeliveryAssetAssignment";
  const requestBody = {
    vendorAssetID: data.VendorAssetID,
    userID: data.UserID,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddDistributorCustomer(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "Distributor/AddDistributorCustomer";
  const requestBody = {
    customerName: data.CustomerName,
    customerPhone: data.CustomerPhone,
    customerMail: data.CustomerMail,
    kraPin: data.KRAPin,
    openingBalance: data.OpeningBalance,
    creditLimit: data.CreditLimit,
    paymentTerms: data.PaymentTerms,
    addedBy: UserID,
    vendorID: VendorID,
    creditLimitNotification: data.CreditLimitNotification,
    isCreditLimitMandatory: data.IsCreditLimitMandatory,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetUserManual = (refresh) => {
  const { data, error } = useApiRes(Main_URL + `AdminReport/GetUserManual`, {
    refresh,
  });
  return { manual: data, error };
};

//cards
export const GetCardCustomerGroup = (refresh) => {
  const { data, error } = useApiRes(
    Main_URL + `FuelCard/GetVendorParentCardGroup?VendorParentID=1`,
    {
      refresh,
    }
  );
  return { data, error };
};

export const GetRewardPointsTypes = (refresh) => {
  const { data, error } = useApiRes(Main_URL + `FuelCard/GetCardPointReward`, {
    refresh,
  });
  return { data, error };
};

export function DeleteCardCustomerGroup(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "FuelCard/RemoveVendorParentCardGroup";
  const requestBody = {
    vendorParentCardGroupID: data.VendorParentCardGroupID,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export function AddCardCustomerGroup(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "FuelCard/PostVendorParentCardGroup";
  const requestBody = {
    vendorParentCardGroupName: data.VendorParentCardGroupName,
    vendorParentID: 1,
    addedBy: UserID,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetRewardLoyaltyRule = (values,refresh) => {
  const { DateRange } = values;
  const start = moment(DateRange[0]).format("YYYY-MM-DD");
  const end = moment(DateRange[1]).format("YYYY-MM-DD");
  const { data, error } = useApiRes(Main_URL + `FuelCard/GetVendorParentCardPointReward?VendorParentID=1&StartDate=${start}&EndDate=${end}`, {
    refresh,
  });
  return { data, error };
};

export function AddRewardLoyaltyRule(data, successFn, errorFn) {
  const asyncSubmit = useAsyncSubmit(successFn, errorFn);
  const apiURL = Main_URL + "FuelCard/PostVendorParentCardPointRewardTx";
  const requestBody = {
    pointRewardName: data.PointRewardName,
    vendorParentCardGroupID: data.VendorParentCardGroupID,
    vendorParentID: 1,
    startDate: data.StartDate,
    endDate: data.EndDate,
    addedBy: UserID,
    rewardDetailArray: data.RewardDetailArray,
    rewardProductArray: data.ProductArray,
    rewardRuleArray: data.RewardRule,
  };
  const handleFormSubmit = async () => {
    asyncSubmit.handleAsyncSubmit(apiURL, requestBody);
  };

  return {
    loading: asyncSubmit.loading,
    submitted: asyncSubmit.submitted,
    success: asyncSubmit.success,
    setSubmitted: asyncSubmit.setSubmitted,
    handleFormSubmit,
  };
}

export const GetCardRedeemIniator = (refresh) => {
  const { data, error } = useApiRes(Main_URL + `FuelCard/GetCardRedeemInitiator`, {
    refresh,
  });
  return { data, error };
};

export const GetCardCustomer = (refresh) => {
  const { data, error } = useApiRes(Main_URL + `FuelCard/GetVendorCustomerCard?VendorCustomerID=254`, {
    refresh,
  });
  return { data, error };
};

import "react-toastify/dist/ReactToastify.css";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import { lazy } from "react";

const InvoiceDetails = lazy(() =>
  import("./components/InvoiceDetails/InvoiceDetails")
);
// const Login = lazy(() => import("./components/Login/Login"));
const SignUp = lazy(() => import("./components/Login/SignUp"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Forgot = lazy(() => import("./components/Password/Forgot"));
const Reset = lazy(() => import("./components/Password/Reset"));
// Settings
const Pump = lazy(() => import("./components/Settings/Pump"));
const Nozzle = lazy(() => import("./components/Settings/Nozzle"));
const Location = lazy(() => import("./components/Settings/Location"));
const Download = lazy(() =>
  import("./components/Settings/DownLoads/Downloads")
);
const Help = lazy(() => import("./components/Settings/Help/index"));
const Profile = lazy(() => import("./components/Settings/Settings"));
const Billing = lazy(() => import("./components/Settings/Billing/index"));
const AuditLog = lazy(() => import("./components/Settings/AuditLogs/AuditTrail"));
const Manual = lazy(() => import("./components/Settings/Manual/Manual"));
//stock
const StockReport = lazy(() => import("./components/Stock/Report"));
const StockAction = lazy(() => import("./components/Stock/StockAction"));
const Purchase = lazy(() => import("./components/Stock/Purchase"));
const Inventory = lazy(() => import("./components/Stock/Inventory"));
const Supplier = lazy(() => import("./components/Stock/Supplier/Suppliers"));
const Offloading = lazy(() =>
  import("./components/Stock/PurchaseDetails/Offloading")
);
//sales
const SaleHistory = lazy(() => import("./components/Invoice/SalesReport"));
const SalesDashboard = lazy(() =>
  import("./components/Invoice/SalesDashboard")
);
const ClientList = lazy(() => import("./components/Invoice/ClientList"));
//Admin
const ClockManagement = lazy(() =>
  import("./components/Admin/ClockManagement")
);
const Error = lazy(() => import("./components/common/error"));
const ShiftSummary = lazy(() =>
  import("./components/Admin/LotSummary/ShiftSummary/index")
);
// AdminReports

// Finance
const Statement = lazy(() => import("./components/Finance/Statement"));
const Expense = lazy(() => import("./components/Finance/Expense"));
const Transfer = lazy(() =>
  import("./components/Finance/FinanceDash/MainDashboard")
);
const Payroll = lazy(() =>
  import("./components/Finance/payrollsheet/PayrollDashboard")
);
const Tax = lazy(() => import("./components/Finance/Tax/Tax"));
//CustomerPage
const CustomerDash = lazy(() =>
  import("./components/CustomerPortal/dashboard")
);
//Consilidated
const ConDashboard = lazy(() => import("./components/Consolidated/Dashboard"));
const LotSummary = lazy(() => import("./components/Admin/LotSummary"));
const DaillySummary = lazy(() =>
  import("./components/Admin/DailyShiftSummary")
);
//DIstributor
const DistributionDashboard = lazy(() =>
  import("./components/Distribution/DashBoard/index")
);
//LoyaltyCards
const LoyaltyCardDashboard = lazy(() =>
  import("./components/LoyaltyCard/DashBoard/index")
);
const LoyaltyCustomers= lazy(() =>
  import("./components/LoyaltyCard/Customers/Customers")
);
const LoyaltySettings= lazy(() =>
  import("./components/LoyaltyCard/Settings/Setting")
);
const LoyaltyProduct= lazy(() =>
  import("./components/LoyaltyCard/Product/Product")
);
//Distributor
const DistributionOrders = lazy(() =>
  import("./components/Distribution/Orders/Orders")
);
const DistributionPurchase = lazy(() =>
  import("./components/Distribution/Stock/Purchase")
);
const DistributionVehicle = lazy(() =>
  import("./components/Distribution/Vehicle/Vehicle")
);
//general
const PrivacyPolicy = lazy(() => import("./api/privacypolicy"));
const VendorContract = lazy(() => import("./VendorContract"));

export const route = [
  { path: "/", layout: "public", component: Home },
  { path: "/invoice/:id", component: InvoiceDetails },
  { path: "/privacypolicy", component: PrivacyPolicy },
  { path: "/contract", component: VendorContract },
  { path: "/login", layout: "public", component: Login },
  { path: "/signup", component: SignUp },
  { path: "/dashboard/dashboard", component: Dashboard },
  { path: "/customers", component: ClientList },
  { path: "/forgot", component: Forgot },
  { path: "/reset/:token", component: Reset },
  { path: "/profile/profile", component: Profile },
  { path: "/settings/pump", component: Pump },
  { path: "/settings/nozzle", component: Nozzle },
  { path: "/settings/billing", component: Billing },
  { path: "/settings/download", component: Download },
  { path: "/settings/auditlogs", component: AuditLog },
  { path: "/settings/manual", component: Manual },
  { path: "/location/location", component: Location },
  { path: "/help/help", component: Help },
  { path: "/sales/saledash", component: SalesDashboard },
  { path: "/sales/history", component: SaleHistory },
  { path: "/sales/customer", component: ClientList },
  { path: "/sales/invoice/history", component: InvoiceDetails },
  { path: "/inventory/report", component: StockReport },
  { path: "/inventory/action", component: StockAction },
  { path: "/inventory/purchase", component: Purchase },
  { path: "/inventory/offloading", component: Offloading },
  { path: "/inventory/inventory", component: Inventory },
  { path: "/inventory/supplier", component: Supplier },
  { path: "/finance/statement", component: Statement },
  { path: "/finance/transfer", component: Transfer },
  { path: "/finance/tax", component: Tax },
  { path: "/finance/expense", component: Expense },
  { path: "/finance/payroll", component: Payroll },
  { path: "/shift/clock", component: ClockManagement },
  { path: "/lotsummary", component: LotSummary },
  { path: "/error", component: Error },
  { path: "/shift/ShiftRecon", component: ShiftSummary },
  { path: "/shift/DailySummary", component: DaillySummary },
  { path: "/customer/dashboard", component: CustomerDash },
  { path: "/consolidated/dashboard", component: ConDashboard },
  { path: "/distribution/dashboard", component: DistributionDashboard },
  { path: "/distribution/orders", component: DistributionOrders },
  { path: "/distribution/purchase", component: DistributionPurchase },
  { path: "/distribution/vehicle", component: DistributionVehicle },
  { path: "/loyalty/dashboard", component: LoyaltyCardDashboard },
  { path: "/loyalty/customers", component: LoyaltyCustomers },
  { path: "/loyalty/settings", component: LoyaltySettings},
  { path: "/loyalty/product", component: LoyaltyProduct},
];

import React, { useEffect, useState } from "react";
import "./styles.css";

const Tabs = (props) => {
  const [active, setActive] = useState(localStorage.getItem("tab"));
  const { Headers, handleTabs } = props;

  const handleClick = (child, idx) => {
    handleTabs(child);
    localStorage.setItem("tab", idx);
    setActive(idx);
  };

  const currentUrl = window.location.href;
  const lastPart = currentUrl.split("/").pop();

  useEffect(() => {
    const newIndex = Headers[0].children.findIndex(
      (item) => item.url.split("/").pop() === lastPart
    );
    setActive(newIndex);
  }, [Headers, lastPart]);

  return (
    <div class="wrapper">
      <div class="tabs_wrap">
        {Headers.map((tab) => (
          <ul>
            {tab.children.map((child, idx) => (
              <li
                style={{
                  background: active === idx ? "#44dc94" : "",
                  color: active === idx ? "#fff" : "",
                }}
                onClick={() => handleClick(child, idx)}
              >
                {child.icon} {child.id}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
